<template>
  <modal id="informationModal">
    <template slot="header" v-if="title">
      <button type="button" class="close close-popup" data-dismiss="modal" aria-label="Close"><span
        aria-hidden="true" @click.prevent="cancelAction">×</span></button>
      <h2 class="modal-title">{{ title }}</h2>
    </template>

    <p v-html="message"></p>

    <template slot="footer" v-if="okCancel">
      <button type="button" class="btn btn-orange" @click.prevent="okAction" :disabled="loading">
        {{ okCancelText[0] }}
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
      </button>
      <button type="button" class="btn btn-orange" @click.prevent="cancelAction" :disabled="loading">
        {{ okCancelText[1] }}
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
      </button>
    </template>
    <template slot="footer" v-else>
      <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-orange" @click.prevent="cancelAction">Close</button>
    </template>
  </modal>
</template>

<script>
  export default {
    props: {
      title: String,
      message: String,
      loading: Boolean,
      okCancel: Boolean,
      okCancelText: {
        type: Array,
        default: () => ['Ok', 'Cancel']
      },
    },
    methods: {
      okAction() {
        this.$emit('ok-action');
        this.$root.$emit('ok-action');
        $('#informationModal').modal('hide');
      },

      cancelAction() {
        this.$emit('cancel-action');
        this.$root.$emit('cancel-action');
        $('#informationModal').modal('hide');
      }
    }
  }
</script>
