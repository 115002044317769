<!--
  /**
    * *NAME: SignUp
    * *PURPOSE: handles the account creation form and necessary logic checks.
    * *ASSOCIATED FILES:
    * *NOTES:
    */
-->

<template>
  <div class="wrap-sign-up">
    <div v-if="showDomainLoginAlert" class="alert msgblock invalidCredentials">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <span>
        An account with this email already exists. If you have an account with
        PRISMATIC POWDERS®, try using those credentials to login, or reset your password
        <a href="#" @click.prevent="$parent.$emit('change-modal', 'forgot')">here</a>.
      </span>
    </div>

    <div class="alert alert-danger msgblock" v-if="formErrors">
      {{ formErrors }}
    </div>

    <div class="alert alert-success msgblock" v-if="formSuccess">
      {{ formSuccess }}
    </div>

    <div v-show="showForm">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': errors.has('first name') }">
            <input type="text" v-validate="'required'" name="given name" placeholder="First Name" v-model="individualSignUpData.given_name" class="form-control" autocomplete="given-name" />
            <span v-show="errors.has('first name')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
              {{ errors.first("first name") }}</span>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group" :class="{ 'has-error': errors.has('last name') }">
            <input type="text" v-validate="'required'" name="family name" placeholder="Last Name" v-model="individualSignUpData.family_name" class="form-control" autocomplete="family-name" />
            <span v-show="errors.has('last name')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
              {{ errors.first("last name") }}</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <input type="text" id="signupCompany" name="name" placeholder="Company Name (Leave blank if none)" v-model="individualSignUpData.company.company" class="form-control" maxlength="30" autocomplete="name" />
      </div>

      <div class="form-group" :class="{ 'has-error': errors.has('country') }">
        <span class="styled-select">
          <select name="country" id="countryCode" v-model="individualSignUpData.signup_country_code" class="form-control" v-validate="'required'" autocomplete="country">
            <option value="" disabled selected>Country</option>
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
            <option value="" disabled>--------------</option>
            <option v-for="country in countries" :value="country.code" :key="country.code">
              {{ country.long_name }}
            </option>
          </select>
        </span>
      </div>

      <div class="form-group" :class="{ 'has-error': errors.has('email') }">
        <input type="username" v-validate="'required|email'" name="email" placeholder="Email" v-model="individualSignUpData.login_email" class="form-control" autocomplete="email"/>
        <span v-show="errors.has('email')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("email") }}</span>
      </div>

      <div class="form-group" :class="{ 'has-error': emailConfirmationHasErrors }">
        <input type="username" v-validate="'required|email'" name="email" placeholder="Confirm Email" v-model="login_email_confirmation" class="form-control" autocomplete="username" />
        <span v-show="emailConfirmationHasErrors" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ emailConfirmationError }}
        </span>
      </div>

      <div class="form-group" :class="{ 'has-error': errors.has('password') }">
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-validate="'required'" name="password new-password" placeholder="Password" v-model="individualSignUpData.login_password" @input="password_check" class="form-control" autocomplete="new-password" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" @click="toggleShow">
              <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </span>
        </div>
        <span v-show="errors.has('password')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("password") }}
        </span>
      </div>

      <div class="form-group" :class="{ 'has-error': pwErrors }">
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-validate="'required'" name="password new-password" placeholder="Confirm Password" v-model="login_password_confirmation" class="form-control" autocomplete="new-password" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" @click="toggleShow">
              <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </span>
        </div>
        <span v-show="pwErrors" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errorMsg }}
        </span>
      </div>

      <PasswordValidation
        :newPassword="individualSignUpData.login_password"
        :confirmPassword="login_password_confirmation"
        @validate-password="setIsValidPassword"
        @password-errors="setPWErrors"
        @error-msg="setErrorMsg"
        ref="pwValidation"
      />

      <div class="form-group">
        <div id="captcha"></div>
        <span v-show="individualSignUpData.captcha_response === null" class="text-danger"><i class="fa fa-exclamation-circle"></i> The reCAPTCHA field is
          required</span>
      </div>

      <div class="form-group text-center">
        <button @click.prevent="signup" class="btn btn-block btn-orange" :disabled="pwErrors || processing || !isValidPassword || !formIsComplete">
          Sign Up
          <i class="fa fa-spinner fa-spin" v-if="processing" aria-hidden="true"></i>
          <i class="fa fa-arrow-right" v-else aria-hidden="true"></i>
        </button>
        <label for="signUpForNewsLetter" class="signUpForNewsletter">
          <input type="checkbox" id="signUpForNewsLetter" v-model="signUpForNewsLetter" name="signUpForNewsLetter" />
          Sign up for promotions
        </label>
        <a href="#" @click.prevent="switchToSignIn" class="btn btn-signup">
          Already have an account? <span>Sign in</span>
        </a>
        <div class="divider-sign-in"><span>OR SIGN UP WITH</span></div>
        <div class="form-group btns-sign-in">
          <a :href="socialLoginUrl('google')" target="_self" class="btn btn-block btn-google">
            <strong>Google</strong>
          </a>
          <a :href="socialLoginUrl('facebook')" target="_self" class="btn btn-block btn-facebook">
            <strong>Facebook</strong>
          </a>
        </div>
        <div class="form-group btns-sign-in mobile-sign-in">
          <a :href="socialLoginUrl('google')" target="_self" class="btn btn-block btn-google"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
          <a :href="socialLoginUrl('facebook')" target="_self" class="btn btn-block btn-facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
        </div>
      </div>
    </div>

    <div class="wrap-business-fields fields-step1">
      <div class="form-group">
        <input type="text" placeholder="Business Name" class="form-control" />
      </div>

      <div class="form-group">
        <input type="text" placeholder="Contact Name" class="form-control" />
      </div>

      <div class="form-group">
        <input type="text" placeholder="Telephone" class="form-control" />
      </div>

      <div class="form-group">
        <span class="styled-select">
          <select name="" id="" class="form-control">
            <option value="">Select</option>
            <option value="">USA</option>
          </select>
        </span>
      </div>

      <div class="circle-steps">
        <span class="circle btn-prev1"></span>
        <span class="circle btn-next"></span>
        <span class="circle"></span>
      </div>

      <div class="form-group text-center">
        <button class="btn btn-block btn-orange btn-next">
          Next <i class="fa" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="wrap-business-fields fields-step2">
      <div class="form-group">
        <input type="text" placeholder="Physical Address" class="form-control" />
      </div>

      <div class="form-group">
        <input type="text" placeholder="Postal Code" class="form-control" />
      </div>

      <div class="form-group">
        <input type="text" placeholder="City" class="form-control" />
      </div>

      <div class="form-group">
        <input type="text" placeholder="State" class="form-control" />
      </div>

      <div class="circle-steps">
        <span class="circle btn-prev1"></span>
        <span class="circle btn-prev2"></span>
        <span class="circle btn-almost"></span>
      </div>

      <div class="form-group text-center">
        <button class="btn btn-block btn-orange btn-almost">
          Almost There <i class="fa" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="wrap-business-fields fields-step3">
      <div class="form-group">
        <input type="email" placeholder="Email Address" class="form-control" />
      </div>

      <div class="form-group">
        <input type="password" placeholder="Password" class="form-control" />
      </div>

      <div class="form-group">
        <input type="password" placeholder="Re-type Password" class="form-control" />
      </div>

      <div class="circle-steps">
        <span class="circle btn-prev1"></span>
        <span class="circle btn-prev2"></span>
        <span class="circle"></span>
      </div>

      <div class="form-group text-center">
        <button class="btn btn-block btn-orange btn-complete">
          Complete <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>

      <p>By logging in, you agree to {{ $root.domainName }}'s <a href="/terms-and-policies" target="_blank">Privacy Policy and Terms of Use</a></p>
    </div>
  </div>

</template>

<script>
  import PasswordValidation from './PasswordValidation.vue';
  import countries from '../../mixins/countries';

  export default {
    name: 'sign-up',

    components: {
      PasswordValidation
    },

    mixins: [countries],

    data() {
      return {
        individualSignUpData: {
          given_name: '',
          family_name: '',
          company: {
            company: '',
          },
          login_email: '',
          login_password: '',
          signup_country_code: '',
          requires_captcha: true,
          captcha_response: false,
        },
        showPassword: false,
        login_password_confirmation: '',
        login_email_confirmation: '',
        processing: false,
        formSuccess: null,
        formErrors: null,
        countries: [],
        signUpForNewsLetter: false,
        hasCompany: false,
        showForm: true,
        showDomainLoginAlert: false,
        isValidPassword: false,
        pwErrors: false,
        errorMsg: ''
      };
    },

    computed: {
      emailConfirmationMatches() {
        return (
          this.individualSignUpData.login_email == this.login_email_confirmation
        );
      },

      emailConfirmationHasErrors() {
        return (
          this.errors.has('email confirmation') || !this.emailConfirmationMatches
        );
      },

      emailConfirmationError() {
        if (!this.emailConfirmationMatches) {
          return 'Emails do not match';
        }

        if (this.errors.has('email confirmation')) {
          return this.errors.first('email confirmation');
        }

        return null;
      },

      formIsComplete() {
        return (
          this.individualSignUpData.family_name !== '' &&
          this.individualSignUpData.given_name !== '' &&
          this.individualSignUpData.signup_country_code !== '' &&
          this.emailConfirmationMatches &&
          this.individualSignUpData.login_password !== '' &&
          this.individualSignUpData.captcha_response !== null &&
          this.individualSignUpData.captcha_response !== false &&
          !this.errors.length
        );
      }
    },

    mounted() {
      let vm = this;
      const captchaParams = {
        sitekey: process.env.MIX_RECAPTCHA_SITEKEY,
        callback: (response) =>
          (vm.individualSignUpData.captcha_response = response),
        expiredCallback: () => (vm.individualSignUpData.captcha_response = null),
        errorCallback: (error) => {
          vm.individualSignUpData.captcha_response = null;
          console.error(error);
        },
      };

      vm.getCountries();

      window.grecaptcha.render('captcha', captchaParams);
    },

    methods: {
      //used password_check, setIsValidPassword, setPWErrors, setErrorMsg for the PasswordValidation component
      password_check() {
        this.$refs.pwValidation.password_check();
      },

      setIsValidPassword(isValid) {
        this.isValidPassword = isValid;
      },

      setPWErrors(errorValue) {
        this.pwErrors = errorValue;
      },

      setErrorMsg(msgValue) {
        this.errorMsg = msgValue;
      },

      toggleShow() {
        this.showPassword = !this.showPassword;
      },

      switchToSignIn() {
        this.$parent.$emit('change-modal', 'signin');
      },

      socialLoginUrl(delegate = 'google') {
        return `${window.appVariables.sso_base_url}/oauth2/delegate-authorize?response_type=code&client_id=${window.appVariables.api_client_id}&redirect_uri=${window.appVariables.app_url}%2Flogin%2Fverify&delegate=${delegate}`;
      },

      async signup() {
        if (this.processing) return false;
        let vm = this;

        vm.showDomainLoginAlert = false;

        try {
          let success = await vm.$validator.validateAll();
          if (
            vm.individualSignUpData.captcha_response === null ||
            vm.individualSignUpData.captcha_response === false
          ) {
            vm.individualSignUpData.captcha_response = null;
            return;
          }

          if (
            !success ||
            vm.pwErrors ||
            !vm.emailConfirmationMatches
          ) {
            return;
          }

          if (!vm.hasCompany) {
            vm.individualSignUpData.company.company =
              vm.individualSignUpData.given_name +
              ' ' +
              vm.individualSignUpData.family_name;

            if (vm.individualSignUpData.company.company.length > 30) {
              vm.individualSignUpData.company.company =
                vm.individualSignUpData.given_name +
                ' ' +
                vm.individualSignUpData.family_name.substring(0, 1);
            }
          }
        } catch (error) {
          console.error(error);
          if (vm.individualSignUpData.captcha_response === false) {
            vm.individualSignUpData.captcha_response = null;
          }
        }

        vm.processing = true;

        try {
          let response = await axios.post(
            '/api/proxify/account/register',
            vm.individualSignUpData
          );

          if (vm.signUpForNewsLetter) {
            // subscribe
            await axios.post(
              `/newsletter-subscribe-email/${vm.individualSignUpData.login_email}`,
              {}
            );
          }

          vm.processing = false;
          vm.individualSignUpData.given_name = '';
          vm.individualSignUpData.family_name = '';
          vm.individualSignUpData.company.company = '';
          vm.individualSignUpData.login_email = '';
          vm.individualSignUpData.login_password = '';
          vm.individualSignUpData.signup_country_code = '';
          vm.formSuccess = response.data.message;
          vm.formErrors = null;
          vm.showForm = false;

          vm.$nextTick(() => {
            vm.errors.clear();
            setTimeout(function () {
              vm.$parent.$emit('change-modal', 'signin');
            }, 5000);
          });
        } catch (error) {
          const apiMessage = error.response.data.message;
          if (apiMessage.includes('Duplicate entry')) {
            vm.showDomainLoginAlert = true;
          } else {
            vm.formErrors = apiMessage;
          }

          vm.processing = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-group .input-group .form-control {
    border-radius: 3px 0 0 3px !important;
  }

  .login-signup .modal-body .btn {
    padding: 16px;

    i {
      margin: 0 !important;
    }
  }

  .login-signup .modal-body p {
    margin: 0;
  }

  .frmLabel {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .frmValidation {
    font-size: 13px;
  }

  .frmValidation--passed {
    color: #717b85;
  }

  .frmIcon {
    color: #eb0029;
  }

  .frmValidation--passed .frmIcon {
    color: #0fa140;
  }
</style>