// This binds the application level global events to the global event bus which is initialized in bootstrap.js

const app = window.app;

window.appVariables.bus.$on('cart-items-added', (cartItem) => {
  app.cart = cartItem.cart;
  app.itemJustAddedToCart.product = cartItem.product;
  app.data = cartItem.data;
  app.multipleItems = cartItem.multipleItems;
  app.swatchItem = cartItem.swatchItem;

  if (cartItem.showBanner) {
    app.showCartBanner();
  }
});

window.appVariables.bus.$on('cart-updated', (cart) => {
  app.cart = cart;
});
