<template>
  <transition-group name="list" tag="div" id="page-notifications" :style="`--header-offset: ${headerOffset}px`">
    <div v-for="notification in unreadNotifications" :class="`list-item notification ${notification.type}`" :key="`notification-${notification.index}`"
         @mouseenter="pause(notification.index)" @mouseleave="start(notification.index)">
        <div class="notification-accent">
          <i :class="`fa fa-${notification.icon}`"></i>
        </div>
        <div class="notification-content">
          <strong class="notification-content-title">{{ notification.title || notification.type }}</strong>
          <p class="notification-content-body">
            <span v-html="notification.message"></span>
          </p>
        </div>
        <button class="notification-close" @click.prevent="remove(notification.index)">×</button>
    </div>
  </transition-group>
</template>

<style lang="scss">
#page-notifications {
  max-height: calc(100vh - var(--header-offset));
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  display: flex;
  z-index: 1050;
  padding: 16px;
  bottom: 0;
  right: 0;
  left: 0;

  .notification {
    box-shadow: 0 5px 10px -5px rgba(black, 0.25);
    background-color: #FFFFFF;
    align-items: stretch;
    pointer-events: all;
    flex-direction: row;
    border-radius: 4px;
    position: relative;
    margin-top: 16px;
    max-width: 100%;
    padding: 16px;
    display: flex;
    width: 450px;
    right: 0;

    &.success {
      --notification-color: #7ECC58;
    }

    &.warning {
      --notification-color: #FFBB35;
    }

    &.error {
      --notification-color: #FF3F49;
    }

    &.info {
      --notification-color: #40CBF9;
    }

    &-accent {
      align-items: center;
      display: flex;

      &::before {
        content: "";
        background-color: var(--notification-color);
        border-radius: 4px;
        height: 100%;
        width: 4px;
      }

      .fa {
        background-color: var(--notification-color);
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        line-height: 48px;
        font-size: 21px;
        margin: 0 24px;
        color: #FFFFFF;
        display: flex;
        height: 48px;
        width: 48px;

        @media screen and (max-width: 1030px) {
          line-height: 36px;
          font-size: 18px;
          margin: 0 16px;
          height: 36px;
          width: 36px;
        }

      }

    }

    &-content {
      flex-direction: column;
      display: flex;
      flex: 1;

      &-title {
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        color: #222222;
        flex: 1;

        &:first-letter {
          text-transform: capitalize;
        }

      }

      &-body {
        color: #7A7A7A;
        margin: 0;

        a:hover,
        a:focus {
          text-decoration: underline;
        }

      }

    }

    &-close {
      background-color: transparent;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      color: #9B9B9B;
      display: flex;
      width: 48px;
      padding: 0;
      border: 0;
    }

  }

}
</style>

<script>
export default {

  name: "notifications",

  data: () => {
    return {

      headerOffset: 0,

      defaults: {
        type: "info",
        icon: "info-circle",
        delay: 5000,
        hidden: false
      },

      notifications: []

    }
  },

  computed: {

    unreadNotifications() {

      return this.notifications.filter(notification => !notification.hidden)

    }

  },

  mounted() {

    this.getHeaderOffset();

  },

  methods: {


    getHeaderOffset() {

      this.headerOffset = document.getElementById("siteNavbar").getBoundingClientRect().height;

    },


    /**
     * Displays a notification message on the applicators profile.
     * The notification can be an info, warning, error, or success message.
     * @param {{ type: "info"|"warning"|"error"|"success", icon: string, message: string, delay: number, index: number }} notification
     */

    add(notification) {

      notification.index = this.notifications.length;
      this.notifications.push({ ...this.defaults, ...notification });
      document.getElementById("page-notifications").scrollTop = 0;
      this.start(notification.index);

    },

    start(index) {

      if(this.notifications[index] && this.notifications[index].delay && this.notifications[index].delay > 0) {

        this.notifications[index].timeout = setTimeout(() => this.remove(index), this.notifications[index].delay);

      }

    },

    pause(index) {

      if(this.notifications[index] && this.notifications[index].timeout) {

        clearTimeout(this.notifications[index].timeout);

      }

    },

    remove(index) {

      if(this.notifications[index]) {

        this.pause(index);
        this.notifications[index].hidden = true;

      }

    }

  }

}
</script>
