<!--
  /**
    * *NAME: ResetPassword
    * *PURPOSE: handles the modal that prompts for a user password rest and checks PW strength/matching.
    * *ASSOCIATED FILES:
    * *NOTES:
    */
-->

<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <div v-if="showExpiredError" class="alert alert-danger">
          Token expired. Please request a new password reset token
          <a href="#" @click.prevent="changeTo('forgot')">here</a>.
        </div>
        <div v-if="response.type" class="alert" :class="`alert-${response.type}`">
          {{ response.message }}
          <a href="#" @click="changeTo('signin')" v-if="response.type === 'success'">
            Click here to log in.
          </a>
        </div>
        <h2 class="resetForm">Reset Password</h2>
      </div>

      <div class="form-group resetForm" :class="{ 'has-error': errors.has('password') }">
        <label for="password">New Password</label>
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-validate="'required'" name="password" v-model="resetPasswordForm.new_password" @input="password_check" id="password" class="form-control" autocomplete="new-password" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" @click="toggleShow">
              <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </span>
        </div>
        <span v-show="errors.has('password')" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errors.first("password") }}
        </span>
        <input type="hidden" v-model="resetPasswordForm.token" id="token" class="form-control" />
      </div>

      <div class="form-group resetForm" :class="{ 'has-error': pwErrors }">
        <label for="password_confirmation">Confirm Password</label>
        <div class="input-group">
          <input :type="showPassword ? 'text' : 'password'" v-validate="'required'" name="Password Confirmation" v-model="confirmPassword" @input="password_check" id="password_confirmation" class="form-control" />
          <span class="input-group-btn"  autocomplete="new-password">
            <button class="btn btn-default" type="button" @click="toggleShow">
              <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </span>
        </div>
        <span v-show="pwErrors" class="text-danger"><i class="fa fa-exclamation-circle"></i>
          {{ errorMsg }}
        </span>
      </div>

      <PasswordValidation
        v-if="!hasSentPWReset"
        :newPassword="resetPasswordForm.new_password"
        :confirmPassword="confirmPassword"
        @validate-password="setIsValidPassword"
        @password-errors="setPWErrors"
        @error-msg="setErrorMsg"
        ref="pwValidation"
      />

      <div class="form-group resetForm text-center">
        <button type="submit" class="btn btn-block btn-orange" :disabled="!isValidPassword">
          Submit
          <i class="fa" :class="{ 'fa-spinner fa-spin': resetPasswordForm.processing, 'fa-arrow-right': !resetPasswordForm.processing }" aria-hidden="true"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import PasswordValidation from './PasswordValidation.vue';

  export default {
    name: 'reset-password',

    components: {
      PasswordValidation
    },

    data() {
      return {
        resetPasswordForm: new Form({
          new_password: '',
          token: window._PR?.token || undefined,
        }),
        showExpiredError: false,
        response: {},
        confirmPassword: '',
        showPassword: false,
        isValidPassword: false,
        pwErrors: false,
        errorMsg: ''
      };
    },

    computed: {
      hasSentPWReset() {
        if (this.response && this.response.type === 'success') {
          return true;
        }

        return false;
      }
    },

    methods: {
      //used password_check, setIsValidPassword, setPWErrors, setErrorMsg for the PasswordValidation component
      password_check() {
        this.$refs.pwValidation.password_check();
      },

      setIsValidPassword(isValid) {
        this.isValidPassword = isValid;
      },

      setPWErrors(errorValue) {
        this.pwErrors = errorValue;
      },
      setErrorMsg(msgValue) {
        this.errorMsg = msgValue;
      },

      password_check() {
        this.has_number = /\d/.test(this.resetPasswordForm.new_password);
        this.has_lowercase = /[a-z]/.test(this.resetPasswordForm.new_password);
        this.has_uppercase = /[A-Z]/.test(this.resetPasswordForm.new_password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
          this.resetPasswordForm.new_password
        );
      },

      toggleShow() {
        this.showPassword = !this.showPassword;
      },

      async submitForm() {
        try {
          if (!(await this.$validator.validateAll())) return;

          if (this.resetPasswordForm.new_password !== this.confirmPassword) {
            this.response = {
              type: 'danger',
              message: 'Confirmation Password Needs to Match',
            };

            return;
          }

          try {
            const { message } = await this.resetPasswordForm.post(
              '/api/proxify/account/confirm'
            );

            this.response = {
              type: 'success',
              message,
            };

            $('.resetForm').css('display', 'none');
          } catch (error) {
            this.showExpiredError = true;
          }
        } catch (error) {
          console.log(error);
        }
      },
      changeTo(view) {
        this.$emit('change-to', view);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-group .input-group .form-control {
    border-radius: 3px 0 0 3px !important;
  }

  .login-signup .modal-body .btn {
    padding: 16px;

    i {
      margin: 0 !important;
    }
  }

  .login-signup .modal-body p {
    margin: 0;
  }

  .frmLabel {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .frmValidation {
    font-size: 13px;
  }

  .frmValidation--passed {
    color: #717b85;
  }

  .frmIcon {
    color: #eb0029;
  }

  .frmValidation--passed .frmIcon {
    color: #0fa140;
  }
</style>
