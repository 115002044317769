// This mixin attach behavior to various DOM elements using third party libraries like gmaps etc.
import signOut from './sign_out';

export default {
  mixins: [
    signOut
  ],

  data() {
    return {
      domainName: 'Cerakote',
      domainTrademark: `CERAKOTE<sup>®</sup>`,
      loading: false,
      showFooter: true,
      notificationBar: [],
      onPageMessage: [],
      onPageShopImg: [],
      onPageHomeImg: [],
      onPageGalleryImg: [],
      onPageApplicatorImg: [],
      onPageCartImg: [],
      showCookieDisabled: false,
      footerNewsletterEmail: "",
      showShopMenuMobile: false,
      showProductTestingMenuMobile: false,
      showIndustriesMenuMobile: false,
      showVsMenuMobile: false,
      showResourcesMenuMobile: false,
      showMyAccountMenuMobile: false,
      desktopHeaderSize: 0,
      mobileHeaderSize: 0,
      // setting the domain name for global use
      domain: 'cerakote',
      isMobile: false,
    };
  },

  computed: {
    thisCountry() {
      if (window.location.pathname === "/canada") {
        return "Canada";
      } else if (
        window.location.pathname === "/deutschland-pulverbeschichtung"
      ) {
        return "Germany";
      } else if (window.location.pathname === "/australia") {
        return "Australia";
      } else if (window.location.pathname === "/sweden") {
        return "Sweden";
      } else if (window.location.pathname === "/uk") {
        return "UK";
      } else if (window.location.pathname === "/europe") {
        return "Europe";
      } else if (window.location.pathname === "/asia") {
        return "Asia";
      } else if (window.location.pathname === "/middle-east") {
        return "Middle East";
      } else if (window.location.pathname === "/hong-kong") {
        return "Hong Kong";
      } else if (window.location.pathname === "/south-korea") {
        return "South Korea";
      } else if (window.location.pathname === "/france") {
        return "France";
      } else if (window.location.pathname === "/south-africa") {
        return "South Africa";
      }
      // else if (window.location.pathname === "/colombia") {
      //   return "Colombia";
      // }
      // else if (window.location.pathname === "/japan") {
      //   return "Japan";
      // }
      // else if (window.location.pathname === "/malaysia") {
      //   return "Malaysia";
      // }
      return "USA";
    },

    /**
     * @function pageName returns the name of the current page to be used in the 'container.vue' component for switching content.
     */

    pageName() {
      let pagePath = window.location.pathname;
      let output = "";

      switch (pagePath) {
        case "/career":
          output = "Careers";
          break;
        case "/about":
          output = "About";
          break;
        case "/resources/documents":
          output = "Documents";
          break;
        case "/project-gallery":
          output = "Gallery";
          break;
        case "/shop/cerakote-mixology":
          output = "Creations";
          break;
        case "/shop/color-match":
          output = "Color Match";
          break;
        case "/my-page":
          output = "Profile";
          break;
      }

      return output;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth <= 640) {
        this.isMobile = true;
      }

      window.addEventListener('resize', this.onResize);
    })

    this.getHeaderSizes();

    let cookieEnabled = navigator.cookieEnabled;
    if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
    }

    if (!cookieEnabled) {
      this.showCookieDisabled = true;
    }
    /**
     * @todo Place this somewhere else, probably in a global event and push that to app_events.js
     */
    this.$on("change-modal", view => {
      this.currentModalView = view;
      if (view === "signin") this.carriedOverMessage = {};
    });

    this.getNotifications();

    window.addEventListener("resize", this.getHeaderSizes);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 640;
    },

    scrollTo(element, offset = 50) {
      let target = 0;

      if (element !== 'top') {
        target = $(element).offset().top - offset;
      }

      $('html, body').animate({
        scrollTop: target,
      }, 500);
    },

    isCurrentShopPage(page) {
      const currentPathArray = window.location.pathname.split('/');
      const currentShopPage = currentPathArray[currentPathArray.length - 1];

      return page === currentShopPage;
    },

    getHeaderSizes() {
      setTimeout(() => {
        this.desktopHeaderSize = `${$("#siteNavbar").outerHeight()}px`;
        document.documentElement.style.setProperty(
          "--desktop-header-size",
          this.desktopHeaderSize
        );

        this.mobileHeaderSize = `${$(".mobile-head-wrapper").outerHeight()}px`;
        document.documentElement.style.setProperty(
          "--mobile-header-size",
          this.mobileHeaderSize
        );
      }, 500);
    },

    openMobileMenu(section) {
      let vm = this;

      switch (section) {
        case "shop":
          vm.showShopMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;

        case "product testing":
          vm.showProductTestingMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;

        case "industries":
          vm.showIndustriesMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;

        case "cerakote-vs":
          vm.showVsMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;

        case "resources":
          vm.showResourcesMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;

        case "your account":
          vm.showMyAccountMenuMobile = true;
          $(".mobile-nav-right .main-menu").addClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").addClass("show-sub-menu");
          break;
      }
    },
    closeMobileMenu(section) {
      let vm = this;

      switch (section) {
        case "shop":
          vm.showShopMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;

        case "product testing":
          vm.showProductTestingMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;

        case "industries":
          vm.showIndustriesMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;

        case "cerakote-vs":
          vm.showVsMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;

        case "resources":
          vm.showResourcesMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;

        case "your account":
          vm.showMyAccountMenuMobile = false;
          $(".mobile-nav-right .main-menu").removeClass("hide-main-menu");
          $(".mobile-nav-right .sub-menu").removeClass("show-sub-menu");
          break;
      }
    },
    currentURL() {
      return window.location.href;
    },
    realURL(url) {
      if (url.startsWith(window.location.origin)) {
        return url;
      }
      return `${window.location.origin}${url}`;
    },
    addToNewsletter(email) {
      const emailRegex = /\S+@\S+\.\S+/g;
      const hasValidEmail = email.length && emailRegex.test(email);

      if (hasValidEmail) {
        axios
          .post(`/newsletter-subscribe-email/${email}`, {})
          .then(response => {
            // do nothing
            alert(
              `You have been successfully added to the ${this.domainName} Newsletter.`
            );
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        alert("Please enter a valid email address.");
      }
    },
    getNotifications() {
      this.getHeaderSizes();

      axios
        .get("/api/proxify/domains/cerakote/notifications")
        .then(response => {
          for (let notification of response.data.data) {
            if (notification.notification_type === "notification_bar") {
              this.notificationBar.push(notification);
            } else if (
              notification.notification_type === "on_page" &&
              notification.on_shop &&
              notification.desktop_image_url
            ) {
              if (!this.onPageShopImg.length) {
                this.onPageShopImg.push(notification);
              }
            } else if (
              notification.notification_type === "on_page" &&
              notification.on_home &&
              notification.desktop_image_url
            ) {
              if (!this.onPageHomeImg.length) {
                this.onPageHomeImg.push(notification);
              }
            } else if (
              notification.notification_type === "on_page" &&
              notification.on_gallery &&
              notification.desktop_image_url
            ) {
              if (!this.onPageGalleryImg.length) {
                this.onPageGalleryImg.push(notification);
              }
            } else if (
              notification.notification_type === "on_page" &&
              notification.on_applicator &&
              notification.desktop_image_url
            ) {
              if (!this.onPageApplicatorImg.length) {
                this.onPageApplicatorImg.push(notification);
              }
            } else if (
              notification.notification_type === "on_page" &&
              notification.on_cart &&
              notification.desktop_image_url
            ) {
              if (!this.onPageCartImg.length) {
                this.onPageCartImg.push(notification);
              }
            } else if (notification.notification_type === "on_page") {
              this.onPageMessage.push(notification);
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 403) {
            console.error(error.response);
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          this.getHeaderSizes();
        });
    },
    showCartBanner() {
      $(".add-to-cart")
        .css("visibility", "visible")
        .addClass("show-cart-popup");
    },
    hideCartBanner() {
      $(".add-to-cart")
        .css("visibility", "hidden")
        .removeClass("show-cart-popup");
    },
    slug(str) {
      if (str) {
        return slugify(str).toLowerCase();
      }

      return "";
    },
    async deleteWishlistItem(productId) {
      const url = `/api/proxify/me/wishlist/${productId}`;

      try {
        let response = await axios.delete(url, { data: {} });
        if (response.data.success) {
          this.$refs[`wishlistItem${productId}`].remove();
        }
      } catch (error) {
        alert("Could not remove from wishlist");
      }
    },

    initiateSignOut() {
      this.signOut(true);
    },
  }
};
