export const coatingsModule = {
  state: {
    colorFilters: window._PR?.colorFilters || [],
    filterOptions: window._PR?.filterOptions || [],
    selectedFilterOptions: window._PR?.selectedFilterOptions || [],
    allCatFilters: window._PR?.allCatCodes || [],
    anyCatFilters: window._PR?.anyCatCodes || [],
    selectedCollectionCategory: window._PR?.selectedCollectionCategory || {},
    collectionFilter: window._PR?.collectionFilter || {},
    products: window._PR?.products || [],
    pageObject: window._PR?.pagination || {},
    searchText: window._PR?.q || '',
    apiQueryParams: {},
    apiExcludedCats: [],
    urlParams: {},
    message: window._PR?.message || '',
    sortOption: (window._PR && window._PR?.orderbyParam) ? window._PR?.orderbyParam : 'rank',
    infiniteId: +new Date(),
    imgLoading: true,
    navBar: true
  },

  getters: {
    imgLoading: state => state.imgLoading,
    navBar: state => state.navBar,
    colorFilters: state => state.colorFilters,
    filterOptions: state => state.filterOptions,
    searchText: state => state.searchText,
    sortOption: state => state.sortOption,
    collectionFilter: state => state.collectionFilter,
    selectedFilterOptions: state => state.selectedFilterOptions.sort((a, b) => a.name.localeCompare(b.name)),
    selectedColorOptions: state => {
      return state.colorFilters
        .filter(filter => filter.checked)
        .map(filter => {
          return {
            name: filter.name,
            code: filter.code
          }
        });
    },
    selectedCollectionCategory: state => state.selectedCollectionCategory,
    anyCatFilters: state => state.anyCatFilters,
    allCatFilters: state => state.allCatFilters,
    infiniteId: state => state.infiniteId,
    message: state => state.message,
    products: state => state.products,
    selectedColorFiltersCsv: state => {
      return state.colorFilters
        .filter(filter => filter.checked)
        .map(filter => filter.code)
        .join()
    },
    selectedCollectionCategory: state => state.selectedCollectionCategory,
    allCollectionCategories: state => {
      const getSubcategories = category => {
        if (Array.isArray(Object.values(category)) && (Object.values(category).length === 0)) return [];

        if (category.sub_categories.length === 0) return category;

        let returnValue = [
          category
        ];

        category.sub_categories
          .map(subCat => getSubcategories(subCat))
          .forEach(cat => {
            if (Array.isArray(cat)) {
              returnValue.push(...cat);
            } else {
              returnValue.push(cat);
            }
          });

        return returnValue;
      };

      return getSubcategories(state.collectionFilter);
    },
    allCollectionCategoryCodes: (state, getters) => getters.allCollectionCategories.map(cat => cat.code),
    allParentFilters: state => {
      const getParentCodes = (categories, parent = null) => {
        let returnValue = [];

        categories.forEach(category => {
          const areSubCategories = category.sub_categories.length > 0;

          if (!areSubCategories) {
            if (parent !== null) {
              const parentAlreadyAdded = returnValue.some(val => val.code === parent.code);

              if (!parentAlreadyAdded) {
                returnValue.push(parent);
              }
            }
          } else {
            returnValue.push(...getParentCodes(category.sub_categories, category));
          }
        });

        return returnValue;
      };

      return getParentCodes(Object.values(state.filterOptions));
    },
    urlQueryParams: state => {
      let params = {};

      Object.keys(state.urlParams).forEach(key => {
        if (state.urlParams[key]) {
          params[key] = state.urlParams[key];
        }
      });

      return params;
    },
    apiQueryParams: state => {
      let params = {};

      Object.keys(state.apiQueryParams).forEach(key => {
        if (state.apiQueryParams[key]) {
          params[key] = state.apiQueryParams[key];
        }
      });

      return params;
    },
    apiExcludedCats: state => state.apiExcludedCats,
    pageObject: state => state.pageObject,
    currentShopPage: () => {
      switch(window.location.pathname) {
        case '/shop/cerakote-kits':
          return 'cera_coating_kits';

        case '/shop/cerakote-swatch-sets':
          return 'cera_swatchsets';

        case '/shop/cerakote-apparel':
          return 'cera_gear';

        case '/shop/cerakote-equipment':
          return 'cera_equipment';

        default:
          return 'cera_all_colors';
      }
    }
  },

  mutations: {
    setImgLoading(state, value) {
      state.imgLoading = value;
    },
    setNavBar(state, value) {
      state.navBar = value;
    },
    setColorFilters(state, value) {
      state.colorFilters = value;
    },
    incrementInfiniteId(state) {
      state.infiniteId++;
    },
    setSortOption(state, value) {
      state.sortOption = value;
    },
    setSelectedFilterOptions(state, value) {
      state.selectedFilterOptions = value;
    },
    setSelectedCollectionCategory(state, value) {
      state.selectedCollectionCategory = value;
    },
    setMessage(state, value) {
      state.message = value;
    },
    setProducts(state, value) {
      state.products = value;
    },
    setSearchText(state, value) {
      state.searchText = value;
    },
    setPageObject(state, value) {
      state.pageObject = value;
    },
    setApiQueryParams(state, value) {
      state.apiQueryParams = value;
    },
    setApiExcludedCats(state, value) {
      state.apiExcludedCats = value;
    },
    setUrlQueryParams(state, value) {
      state.urlParams = value;
    }
  },

  actions: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    toggleColorFilter({ getters, commit, dispatch }, filterCode) {
      commit('setColorFilters', getters.colorFilters.map(f => {
        if (f.code === filterCode) f.checked = !f.checked;

        return f;
      }));

      dispatch('resetLoading');
    },
    toggleCollectionShowAll({ getters, commit, dispatch }, filterData) {
      // Clear out any currently selected collection filters
      const newSelectedFilterOptions = getters.selectedFilterOptions
        .filter(option => {
          const isClickedFilter = option.code === filterData.clickedFilter.code;
          const isCollectionFilter = getters.allCollectionCategoryCodes.includes(option.code);

          return isClickedFilter || !isCollectionFilter;
        });

      commit('setSelectedFilterOptions', newSelectedFilterOptions);

      dispatch('toggleFilterOption', filterData);
    },
    toggleFilterOption({ getters, commit, dispatch }, { clickedFilter, filterParent }) {
      let newSelectedFilterOptions = getters.selectedFilterOptions;
      const parentFilter = filterParent;

      // If the filter parent (i.e. "Gloss Levels", "Finishes", etc.) only
      // allows for one filter to be selected in it, go through each filter
      // option and select or deselect accordingly
      if (!filterParent.multi_selectable) {
        filterParent.sub_categories = filterParent.sub_categories.map(filterOpt => {
          const isSelected = newSelectedFilterOptions.map(cat => cat.code).includes(filterOpt.code);
          const filterOptionIsSelectedButIsNotTheClickedFilter = isSelected && filterOpt.code !== clickedFilter.code;
          const filterOptionIsSelectedAndIsTheClickedFilter = isSelected && filterOpt.code === clickedFilter.code;
          const filterOptionIsNotSelectedButIsTheClickedFilter = !isSelected && filterOpt.code === clickedFilter.code;

          if (filterOptionIsSelectedButIsNotTheClickedFilter) {
            filterOpt.selected = false;
            newSelectedFilterOptions = newSelectedFilterOptions.filter(opt => opt.code !== filterOpt.code);
          } else if (filterOptionIsSelectedAndIsTheClickedFilter) {
            filterOpt.selected = false;
          } else if (filterOptionIsNotSelectedButIsTheClickedFilter) {
            filterOpt.selected = true;
          }

          return filterOpt;
        });
      }

      const clickedFilterIsAlreadySelected = newSelectedFilterOptions
        .map(filterOption => filterOption.code)
        .includes(clickedFilter.code);

      // If the clicked filter is already selected...
      if (clickedFilterIsAlreadySelected) {
        // ...filter it out of the list of selected filters...
        newSelectedFilterOptions = newSelectedFilterOptions.filter(item => item.code !== clickedFilter.code);
      } else { // ...otherwise, add it to the list of selected filters
        newSelectedFilterOptions = [
          ...newSelectedFilterOptions,
          clickedFilter
        ];
      }

      const parentFilterIsSelected = newSelectedFilterOptions
        .map(filterOption => filterOption.code)
        .includes(parentFilter.code);

      if (parentFilterIsSelected) {
        newSelectedFilterOptions = newSelectedFilterOptions.filter(item => item.code !== parentFilter.code);
      }

      commit('setSelectedFilterOptions', newSelectedFilterOptions);

      const isCollectionFilter = getters.allCollectionCategoryCodes.includes(clickedFilter.code);

      if (isCollectionFilter) {
        if (!clickedFilterIsAlreadySelected) {
          commit('setSelectedCollectionCategory', clickedFilter);
        } else {
          commit('setSelectedCollectionCategory', getters.collectionFilter);
        }
      }

      dispatch('resetLoading');
    },
    mobileSearchShop({ getters, dispatch }) {
      if (getters.searchText.length > 2) {
        dispatch('searchShop');
      }
    },
    searchShop({ getters, commit, dispatch }) {
      commit('setUrlQueryParams', Object.assign(getters.urlQueryParams, {
        q: getters.searchText
      }));

      dispatch('resetLoading');
    },
    sortProducts({ getters, commit, dispatch }, sortOption) {
      commit('setSortOption', sortOption);

      let newUrlQueryParams = _.cloneDeep(getters.urlQueryParams);

      if (getters.sortOption === 'rank') {
        delete newUrlQueryParams['category'];
        window._PR.category = 'default';
      } else {
        newUrlQueryParams = Object.assign(getters.urlQueryParams, {
          category: 'created_at'
        });
        window._PR.category = 'created_at';
      }

      commit('setUrlQueryParams', newUrlQueryParams);
      dispatch('resetLoading');
    },
    resetFilters({ getters, commit, dispatch }) {
      commit('setSearchText', '');
      commit('setSortOption', 'rank');
      commit('setColorFilters', getters.colorFilters.map(f => {
        f.checked = false;

        return f;
      }));
      commit('setSelectedFilterOptions', []);
      commit('setApiQueryParams', {});
      commit('setUrlQueryParams', {});
      commit('setSelectedCollectionCategory', getters.collectionFilter);

      dispatch('resetLoading');
    },
    resetLoading({ getters, commit, dispatch }) {
      dispatch('scrollTop');

      setTimeout(() => {
        commit('setMessage', '');
        commit('setProducts', []);
        commit('setApiQueryParams', {});
        commit('setApiExcludedCats', []);
        commit('setUrlQueryParams', {});
        commit('setPageObject', Object.assign(getters.pageObject, { page: 0 }));
        commit('incrementInfiniteId');
      }, 500);
    },
    updateApiQueryParams({ getters, commit }) {

      const appliedAllCatOptions = getters.selectedFilterOptions
        .filter(cat => getters.allCatFilters.map(allCat => allCat.code).includes(cat.code))
        .map(cat => cat.code)
        .join(',');

      const newApiQueryParams = Object.assign(getters.apiQueryParams,{
        page: getters.pageObject.page + 1,
        limit: getters.pageObject.items_per_page,
        allcats: getters.currentShopPage + (appliedAllCatOptions ? ',' + appliedAllCatOptions : ''),
        orderby: getters.sortOption,
        orderdir: 'desc',
        hide_discontinued: 'false',
        // hide_hero_only: 'false',
        q: getters.searchText,
        fields: [
          'id',
          'url',
          'featured_image_url',
          'name',
          'sku',
          'discontinued',
          'quantity_available',
          'base_quantity.unit',
          'base_quantity.value',
          'product_id',
          'swatch_product.id',
          'swatch_product.enabled',
          'swatch_product.hero_only',
          'pricing.**'
        ].join()
      });

      const anycatFiltersCsv = getters.selectedFilterOptions
        .filter(cat => getters.anyCatFilters.map(allCat => allCat.code).includes(cat.code))
        .map(cat => cat.code)
        .join();

      const selectedColorFiltersCsv = getters.colorFilters
        .filter(filter => filter.checked)
        .map(filter => filter.code)
        .join();

      if (selectedColorFiltersCsv.length) {
        let anycatStr = selectedColorFiltersCsv;

        if (anycatFiltersCsv.length) {
          anycatStr += `,${anycatFiltersCsv}`;
        }

        newApiQueryParams['anycat'] = anycatStr;
      } else if (anycatFiltersCsv.length) {
        newApiQueryParams['anycat'] = anycatFiltersCsv;
      }

      commit('setApiQueryParams', newApiQueryParams);

    },

    updateUrlQueryParams({ getters, commit }) {
      const selectedColorFiltersCsv = getters.colorFilters
        .filter(filter => filter.checked)
        .map(filter => filter.code)
        .join();
      const newUrlQueryParams = {
        q: getters.searchText,
        color: selectedColorFiltersCsv,
        category: getters.sortOption !== 'rank' ? 'created_at' : '',
        finishes: getters.selectedFilterOptions.map(cat => cat.code).join()
      };

      commit('setUrlQueryParams', newUrlQueryParams);
    },
    async updateUrl({ getters, commit }) {
      // remove any trailing slash in url
      if (window.location.href.endsWith('/')) {
        window.history.replaceState('', '', window.location.href.substr(0, window.location.href.length - 1));
      }

      if (!_.isEmpty(getters.urlQueryParams)) {
        // Sort the object's keys to maintain the same url param order for color and finishes etc
        commit('setUrlQueryParams', Object.keys(getters.urlQueryParams).sort().reduce(
          (obj, key) => {
            obj[key] = getters.urlQueryParams[key];
            return obj;
          },
          {}
        ));

        // TODO: Maybe should be pushState?
        window.history.pushState('', '', `?${$.param(getters.urlQueryParams)}`);
      } else {
        window.history.pushState('', '', window.location.href.split('?')[0]);
      }

      /**
       * @see https://app.shortcut.com/nicindustries/story/19777/sharing-product-search-page-does-not-contain-filter
       * @desc will update the page canonical url if it matches the canonical list and the url has query params.
       */
      const canonical = document.querySelector('link[rel="canonical"]');
      const queryParams = Object.values(getters.urlQueryParams)[0];
      const canonicalParams = {
        page: 'shop',
        finishes: queryParams,
      }
      let hasStoredCanonical = false;

      // 👇 limit api calls
      if (queryParams !== undefined) {
        const {data: res} = await axios.get('/api/proxify/domains/cerakote/canonical/find-match', { params: canonicalParams});

        if (Object.keys(res).length > 0) {
          hasStoredCanonical = true;
        }
      }

      if (canonical !== null && queryParams === undefined || hasStoredCanonical) {
        canonical.href = location.href;
      }
    },

    async renderProducts({ getters, commit, dispatch }) {
      dispatch('updateApiQueryParams');
      dispatch('updateUrlQueryParams');
      dispatch('updateUrl');

      try {
        const productUrl = '/api/proxify/domains/cerakote/shop/products';
        const { data: products } = await axios.get(productUrl, { params: getters.apiQueryParams });

        if (products.pagination) {
          if (products.pagination.page === 1) {
            if (!products.data.length) {
              if (getters.currentShopPage !== 'cera_all_colors') {
                commit('setMessage', 'Sorry, no matching colors');
              } else {
                commit('setMessage', 'Sorry, no matching products');
              }
            }

            commit('setProducts', products.data);
          } else {
            commit('setProducts', [
              ...getters.products,
              ...products.data
            ]);
          }

          if (!products.data.length) {
            return Promise.resolve('complete');
          }

          commit('setPageObject', products.pagination);
          return Promise.resolve('loaded');
        } else {
          return Promise.resolve('complete');
        }
      } catch(error) {
        return Promise.reject(error);
      }
    }
  }
};
