//For Main nav on all touch devices
window.is_touch_device = function() {
	$('.navbar-nav li > ul').each(function (index, ev) {
        // Use this to keep the href on the <a> intact but prevent the default action
        $(ev).prev('a').on('click', function (event) {
        	if (!$(this).hasClass('goToLink')) {
        		event.preventDefault();
        	}
        	$(this).addClass('goToLink');
        });
    });
    $('.gallery-full .thumbnail-box').addClass('box-on-touch');
};
$(document).ready(function () {
    'use strict';

    // home banner Slider
    $("#homeCarousel li").click(function(){
        $('#homeCarousel').carousel('pause');
    });

    is_touch_device();
    /* -- Start Main nav scripts -- */
    $('ul.nav > li.dropdown').hover(function () {
        $(this).find('> .dropdown-menu').stop(true, true).delay(25).fadeIn(400);
    }, function () {
        $(this).find('> .dropdown-menu').stop(true, true).delay(25).fadeOut(400);
    });

    $('li.dropdown.dropdown-submenu').hover(function () {
        $(this).find('> .dropdown-menu').stop(true, true).delay(25).fadeIn(400);
    }, function () {
        $(this).find('> .dropdown-menu').stop(true, true).delay(25).fadeOut(400);
    });


    // For Active main nav link
    $("li:has(.dropdown-menu li.current-menu-item) > a, li:has(.dropdown-menu .dropdown-menu li.current-menu-item) > a").css({
        'border-bottom': '3px solid #fff',
        'padding': '0'
    });

    // Mobile Nav Button
    $('nav .navbar-toggle').on('click', function () {
        $(this).toggleClass('open-menu');
    });

    // Thumbnail Slider
    $('#productCarousel').carousel();

    // handles the carousel thumbnails
    $('[id^=carousel-selector-]').click(function () {
        var id = $(this).attr("accesskey");
        id = parseInt(id);
        $('#productCarousel, #galleryCarousel, #creationCarousel').carousel({interval: false});
        $('#productCarousel, #galleryCarousel, #creationCarousel').carousel(id);
        $('[id^=carousel-selector-]').removeClass('selected');
        $(this).addClass('selected');
    });

    $('#productCarousel, #galleryCarousel, #creationCarousel').bind('slide.bs.carousel', function (e) {
        var slideFrom = $(this).find('.active').index();
        var videoContent = $('.item[data-slide-number=' + slideFrom + '] .embed-responsive');
        videoContent.html(videoContent.html());
    });

    // when the carousel slides, auto update
    $('#productCarousel, #galleryCarousel, #creationCarousel').on('slid', function (e) {
        var id = $('.item.active').data('slide-number');
        id = parseInt(id);
        $('[id^=carousel-selector-]').removeClass('selected');
        $('[id=carousel-selector-' + id + ']').addClass('selected');
    });

    // Mobile Nav Toggle
    $('.nav-toggle-mob').on('click', function () {
        $('.mobile-nav').css('left', '0%');
    });
    $('.close-mobile-nav, .close-toggle-nav').on('click', function () {
        $('.mobile-nav').css('left', '-100%');
    });

    // To set slider zoom icons
//    $('#galleryCarousel .item-in').on('click', function () {
//        $(this).toggleClass('item-in-zoom');
//    });

    // To Replace Object Fit in IE.Edge
    /*if ( ! Modernizr.objectfit ) {
     $('.banner-black-overlay, .img-gallery-half, .img-gallery-column1, img-gallery-column2, .img-gallery-column1-3, .hero-mid-large').each(function () {
     var $container = $(this),
     imgUrl = $container.find('img').prop('src');
     if (imgUrl) {
     $container
     .css('backgroundImage', 'url(' + imgUrl + ')')
     .addClass('compat-object-fit');
     }
     });
     }*/

    // To add another phone field
    $('.btn-add-phone').on('click', function () {
        $('.field-add-phone').css('display', 'block');
    });

    // To Get Input File Image/Name
    // $("input#file-upload").change(function (e) {
    //     for (var i = 0; i < e.originalEvent.srcElement.files.length; i++) {
    //         var file = e.originalEvent.srcElement.files[i];
    //         var $img = $('<img>');
    //         var reader = new FileReader();
    //         reader.onloadend = function () {
    //             $img.attr('src', reader.result);
    //         };
    //         var $imageWrapper = $('<div class=img-wrapper>').append('<span class="fa fa-times delete-image">');
    //         $imageWrapper.append($img);
    //         reader.readAsDataURL(file);
    //         $("input#file-upload").after($imageWrapper);
    //     }
    // });
    // $('.delete-image').on('click', function () {
    //     console.log($(this));
    //     console.log($(this).closest('.img-wrapper'));
    //     $(this).closest('.img-wrapper').delete();
    // });

    $('#file-upload').on('change', function () {
        $('#file-name').html($(this).val());
        var filename = $(this).val();
        $('#filename').html(filename);
    });

    $('.styled-checkbox2 input[type="checkbox"]').on('change', function () {
        if ($(this).is(":checked")) {
            $('.wrap-sign-up').hide();
            $('.fields-step1').show();
        } else {
            $('.wrap-sign-up').show();
            $('.fields-step1, .fields-step2, .fields-step3').hide();
        }
    });

    if(window._PR?.isResetView) {
        $('body-reset-password').show();
    }
    $('.btn-next').on('click', function () {
        $('.fields-step1').hide();
        $('.fields-step2').show();
    });
    $('.btn-almost').on('click', function () {
        $('.fields-step2').hide();
        $('.fields-step3').show();
    });
    $('.btn-complete').on('click', function () {
        $('.body-signup, .fields-step3').hide();
        $('.visit-your-page').show();
    });
    $('.btn-prev1').on('click', function () {
        $('.fields-step2, .fields-step3').hide();
        $('.fields-step1').show();
    });
    $('.btn-prev2').on('click', function () {
        $('.fields-step1, .fields-step3').hide();
        $('.fields-step2').show();
    });

    // Swatch Method box change on click
    $('.swatch-address').on('click', '.swatch-box', function () {
        $('.swatch-box').removeClass('method-orange');
        $(this).addClass('method-orange');
    });

    // Hide add to cart popup
    $('.mid-inner').on('click', function () {
    	if ($('.add-to-cart').hasClass('show-cart-popup')) {
    		$('.add-to-cart').css('visibility', 'hidden').removeClass('show-cart-popup');
    	}
    });

    // Shipping Method box change on click
    $('.shipping-address').on('click', '.method-box-click', function () {
        $('.method-box-click').removeClass('method-orange');
        $(this).addClass('method-orange');
    });

    // Gallery Option on click
    $('.gallery-full').on('click', '.thumbnail-box .arrow-up' , function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
            $(this).parent('.thumbnail-box').find('.info-thumbnail').css('bottom', '-50%');
        } else {
            $(this).addClass('selected');
            $(this).parent('.thumbnail-box').find('.info-thumbnail').css('bottom', '0');
        }
    });

    // Gallery Option on click
    $('.creation-full').on('click', '.creation-thumbnail-box .arrow-up' , function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
            $(this).parent('.creation-thumbnail-box').find('.info-thumbnail').css('bottom', '-37%');
        } else {
            $(this).addClass('selected');
            $(this).parent('.creation-thumbnail-box').find('.info-thumbnail').css('bottom', '0');
        }
    });


    $('.newest').on('click', function () {
    	$('.trending').parent().removeClass('active');
      if($(this).parent().hasClass('active')) {
      	$(this).parent().removeClass('active');
      } else {
      	$(this).parent().toggleClass('active');
      }
    });

    $('.trending').on('click', function () {
    	$('.newest').parent().removeClass('active');
      if($(this).parent().hasClass('active')) {
      	$(this).parent().removeClass('active');
      } else {
      	$(this).parent().toggleClass('active');
      }
    });

    // To show/hide all filter colors
    $('.all-colors').on('click', function () {
    	$('.top-searches').parent().removeClass('active');
      if($('.filter-options').hasClass('show')) {
      	$(this).parent().removeClass('active');
        $('.filter-options').removeClass('show');
      } else {
      	$(this).parent().toggleClass('active');
        $('.filter-options').addClass('show');
        $('.wrap-top-searches').removeClass('show');
      }

//        $('.filter-options').css('display', 'block');
//        $('.wrap-top-searches').css('display', 'none');
    });

    // To show/hide Top Searches
    $('.top-searches').on('click', function () {
    	$('.all-colors').parent().removeClass('active');
      if($('.wrap-top-searches').hasClass('show')) {
      	$(this).parent().removeClass('active');
      	$('.wrap-top-searches').removeClass('show');
      } else {
      	$(this).parent().toggleClass('active');
        $('.filter-options').removeClass('show');
        $('.wrap-top-searches').addClass('show');
      }

//        $('.wrap-top-searches').css('display', 'block');
//        $('.filter-options').css('display', 'none');
    });

    // To show/hide Q&A Form
    $('#askQuestion').on('click', function () {
        $(this).hide();
        $('.form-question').show();
    });
    $('#btnCancel').on('click', function () {
        $('#askQuestion').show();
        $('.form-question').hide();
    });

    // Image zoom in same place on click
    (function ($) {
        var defaults = {
            url: false,
            callback: false,
            target: false,
            duration: 120,
            on: 'mouseover', // other options: grab, click, toggle
            touch: true, // enables a touch fallback
            onZoomIn: false,
            onZoomOut: false,
            magnify: 4
        };

        // Core Zoom Logic, independent of event listeners.
        $.zoom = function (target, source, img, magnify) {
            var targetHeight,
                targetWidth,
                sourceHeight,
                sourceWidth,
                xRatio,
                yRatio,
                offset,
                $target = $(target),
                position = $target.css('position'),
                $source = $(source);

            // The parent element needs positioning so that the zoomed element can be correctly positioned within.
            target.style.position = /(absolute|fixed)/.test(position) ? position : 'relative';
            target.style.overflow = 'hidden';
            img.style.width = img.style.height = '';

            $(img)
                .addClass('zoomImg')
                .css({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    width: img.width * magnify,
                    height: img.height * magnify,
                    border: 'none',
                    maxWidth: 'none',
                    maxHeight: 'none'
                })
                .appendTo(target);

            return {
                init: function () {
                    targetWidth = $target.outerWidth();
                    targetHeight = $target.outerHeight();

                    if (source === target) {
                        sourceWidth = targetWidth;
                        sourceHeight = targetHeight;
                    } else {
                        sourceWidth = $source.outerWidth();
                        sourceHeight = $source.outerHeight();
                    }

                    xRatio = (img.width - targetWidth) / sourceWidth;
                    yRatio = (img.height - targetHeight) / sourceHeight;

                    offset = $source.offset();
                },
                move: function (e) {
                    var left = (e.pageX - offset.left),
                        top = (e.pageY - offset.top);

                    top = Math.max(Math.min(top, sourceHeight), 0);
                    left = Math.max(Math.min(left, sourceWidth), 0);

                    img.style.left = (left * -xRatio) + 'px';
                    img.style.top = (top * -yRatio) + 'px';
                }
            };
        };

        $.fn.zoom = function (options) {
            return this.each(function () {
                var
                    settings = $.extend({}, defaults, options || {}),
                    //target will display the zoomed image
                    target = settings.target && $(settings.target)[0] || this,
                    //source will provide zoom location info (thumbnail)
                    source = this,
                    $source = $(source),
                    img = document.createElement('img'),
                    $img = $(img),
                    mousemove = 'mousemove.zoom',
                    clicked = false,
                    touched = false;

                // If a url wasn't specified, look for an image element.
                if (!settings.url) {
                    var srcElement = source.querySelector('img');
                    if (srcElement) {
                        settings.url = srcElement.getAttribute('data-src') || srcElement.currentSrc || srcElement.src;
                    }
                    if (!settings.url) {
                        return;
                    }
                }

                $source.one('zoom.destroy', function (position, overflow) {
                    $source.off(".zoom");
                    target.style.position = position;
                    target.style.overflow = overflow;
                    img.onload = null;
                    $img.remove();
                }.bind(this, target.style.position, target.style.overflow));

                img.onload = function () {
                    var zoom = $.zoom(target, source, img, settings.magnify);

                    function start(e) {
                        zoom.init();
                        zoom.move(e);

                        // Skip the fade-in for IE8 and lower since it chokes on fading-in
                        // and changing position based on mousemovement at the same time.
                        $img.stop()
                            .fadeTo($.support.opacity ? settings.duration : 0, 1, $.isFunction(settings.onZoomIn) ? settings.onZoomIn.call(img) : false);
                    }

                    function stop() {
                        $img.stop()
                            .fadeTo(settings.duration, 0, $.isFunction(settings.onZoomOut) ? settings.onZoomOut.call(img) : false);
                    }

                    // Mouse events
                    if (settings.on === 'grab') {
                        $source
                            .on('mousedown.zoom',
                                function (e) {
                                    if (e.which === 1) {
                                        $(document).one('mouseup.zoom',
                                            function () {
                                                stop();

                                                $(document).off(mousemove, zoom.move);
                                            }
                                        );

                                        start(e);

                                        $(document).on(mousemove, zoom.move);

                                        e.preventDefault();
                                    }
                                }
                            );
                    } else if (settings.on === 'click') {
                        $source.on('click.zoom',
                            function (e) {
                                if (clicked) {
                                    // bubble the event up to the document to trigger the unbind.
                                    return;
                                } else {
                                    clicked = true;
                                    start(e);
                                    $(document).on(mousemove, zoom.move);
                                    $(document).one('click.zoom',
                                        function () {
                                            stop();
                                            clicked = false;
                                            $(document).off(mousemove, zoom.move);
                                        }
                                    );
                                    return false;
                                }
                            }
                        );
                    } else if (settings.on === 'toggle') {
                        $source.on('click.zoom',
                            function (e) {
                                if (clicked) {
                                    stop();
                                } else {
                                    start(e);
                                }
                                clicked = !clicked;
                            }
                        );
                    } else if (settings.on === 'mouseover') {
                        zoom.init(); // Preemptively call init because IE7 will fire the mousemove handler before the hover handler.

                        $source
                            .on('mouseenter.zoom', start)
                            .on('mouseleave.zoom', stop)
                            .on(mousemove, zoom.move);
                    }

                    // Touch fallback
                    if (settings.touch) {
                        $source
                            .on('touchstart.zoom', function (e) {
                                e.preventDefault();
                                if (touched) {
                                    touched = false;
                                    stop();
                                } else {
                                    touched = true;
                                    start(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0]);
                                }
                            })
                            .on('touchmove.zoom', function (e) {
                                e.preventDefault();
                                zoom.move(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0]);
                            })
                            .on('touchend.zoom', function (e) {
                                e.preventDefault();
                                if (touched) {
                                    touched = false;
                                    stop();
                                }
                            });
                    }

                    if ($.isFunction(settings.callback)) {
                        settings.callback.call(img);
                    }
                };

                img.src = settings.url;
            });
        };

        $.fn.zoom.defaults = defaults;
    }(window.jQuery));
//    $(window).on('resize load', function(){
//
//      var ww = $(window).width();
//      if (ww >= 768) {
//    		$('#productCarousel > .carousel-inner > .item > .item-in, #galleryCarousel > .carousel-inner > .item > .item-in').zoom({on: 'click'});
//      }
//    });
//    $(window).trigger('resize');
});
