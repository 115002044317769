import Vue from "vue";
import Vuex from "vuex";
import { coatingsModule } from "./coatings-module";
import { checkoutModule } from "./checkout-module";
import { cartModule } from "./cart-module";
import { authModule } from "./auth-module";

Vue.use(Vuex);

const modules = {
  coatingsModule,
  checkoutModule,
  cartModule,
  authModule,
};

export default new Vuex.Store({
  namespaced: true,
  modules,
});
