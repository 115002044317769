export default {
  shippingOptions(id) {
    return axios.get(`/api/proxify/domains/cerakote/shop/checkouts/${id}/shipping-options`);
  },
  update(id, payload) {
    return axios.patch(`/api/proxify/domains/cerakote/shop/checkouts/${id}`, payload);
  },
  submit(id, payload = {}) {
    return axios.post(`/api/proxify/domains/cerakote/shop/checkouts/${id}/submit`, payload);
  }
}
