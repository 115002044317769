<template>
  <div>
    <div class="wrap-sign-up">
      <div v-if="showDomainLoginAlert" class="alert msgblock invalidCredentials">
        <i class="fa fa-exclamation-circle" aria-hidden="true" />
        <span>
          An account with this email already exists. If you have a PRISMATIC POWDERS® account,
          try using those credentials to login, or reset your password
          <a href="#" @click.prevent="$parent.$emit('change-modal', 'forgot')">here</a>.
        </span>
      </div>
      <div class="alert alert-danger msgblock" v-if="formErrors">
        {{ formErrors }}
      </div>
      <div class="alert alert-success msgblock" v-if="formSuccess">
        {{ formSuccess }}
      </div>
      <div v-if="showForm">
        <div class="alert alert-danger msgblock">We just need a little more information from you to complete your account registration. <strong>This information is required for you to place an order.</strong></div>

        <!-- Customer Name -->
        <div class="row">

          <!-- First Name -->
          <div class="col-sm-6">
            <div class="form-group" :class="{ 'has-error': errors.has('first name') }">
              <input type="text" v-validate="'required'" name="first name" placeholder="First Name"
                    v-model="individualSignUpData.given_name" class="form-control">
              <span v-show="errors.has('first name')" class="text-danger" style="color: #ff6961;"><i class="fa fa-exclamation-circle"></i> {{ errors.first('first name') }}</span>
            </div>
          </div>

          <!-- Last Name -->
          <div class="col-sm-6">
            <div class="form-group" :class="{ 'has-error': errors.has('last name') }">
              <input type="text" v-validate="'required'" name="last name" placeholder="Last Name"
                    v-model="individualSignUpData.family_name" class="form-control">
              <span v-show="errors.has('last name')" class="text-danger" style="color: #ff6961;"><i class="fa fa-exclamation-circle"></i> {{ errors.first('last name') }}</span>
            </div>
          </div>
        </div>

        <!-- Company Name -->
        <div class="form-group">
          <input type="text" id="signupCompany" name="company" placeholder="Company Name (Leave blank if none)"
                v-model="individualSignUpData.company.company" class="form-control" maxlength="30">
        </div>

        <!-- Country Selection -->
        <div class="form-group" :class="{ 'has-error': errors.has('country') }">
          <span class="styled-select">
            <select name="country"
                    id="countryCode"
                    v-model="individualSignUpData.signup_country_code"
                    class="form-control"
                    v-validate="'required'">
              <option value="" disabled selected>Country</option>
              <option value="USA">United States</option>
              <option value="CAN">Canada</option>
              <option value="" disabled>--------------</option>
              <option v-for="country in countries" :value="country.code" :key="country.code">
                {{ country.long_name }}
              </option>
            </select>
          </span>
        </div>

        <!-- Email -->
        <div class="form-group" :class="{ 'has-error': errors.has('email') }">
          <input type="email" v-validate="'required|email'" name="email" placeholder="Email"
                v-model="individualSignUpData.login_email" class="form-control">

          <span v-show="errors.has('email')" class="text-danger" style="color: #ff6961;"><i class="fa fa-exclamation-circle"></i>
            {{ errors.first('email') }}
          </span>
        </div>

        <!-- Email Confirmation -->
        <div class="form-group" :class="{ 'has-error': emailConfirmationHasErrors }">
          <input type="email" v-validate="'required|email'" name="email confirmation" placeholder="Confirm Email"
                v-model="login_email_confirmation" class="form-control">

          <span v-show="passwordConfirmationHasErrors" class="text-danger" style="color: #ff6961;"><i class="fa fa-exclamation-circle"></i>
            {{ emailConfirmationError }}
          </span>
        </div>

        <div class="form-group text-center">
          <button @click.prevent="signup" class="btn btn-block btn-orange" :disabled="errors.any() || processing">
            Complete Registration
            <i class="fa" :class="{ 'fa-spinner fa-spin' : processing, 'fa-arrow-right' : !processing }" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import countries from '../../mixins/countries';

  export default {
    name: 'sso-finish-account-creation',

    mixins: [countries],

    data() {
      return {
        individualSignUpData: {
          given_name: '',
          family_name: '',
          company: {
            company: '',
          },
          login_email: '',
          signup_country_code: '',
          delegate_session_id: window._PR?.sessionId || ''
        },
        delegateType: '',
        sessionId: window._PR?.sessionId || '',
        login_email_confirmation: '',
        processing: false,
        formSuccess: null,
        formErrors: null,
        countries: [],
        signUpForNewsLetter: false,
        hasCompany: false,
        showForm: true,
        showDomainLoginAlert: null
      };
    },

    mounted() {
      let vm = this;
      vm.getCountries();
      vm.getTempDelegateAccountInfo();
      if(vm.delegateType === 'google' || vm.delegateType === 'facebook'){
        vm.openLoginModal();
      }
    },

    computed: {
      isThisModalViewActive() {
        return this.$parent.currentModalView === 'delegateRegistration';
      },
      passwordConfirmationMatches() {
        return this.individualSignUpData.login_password === this.login_password_confirmation;
      },
      passwordConfirmationHasErrors() {
        return this.errors.has('password confirmation') || !this.passwordConfirmationMatches;
      },
      passwordConfirmationError() {
        if (!this.passwordConfirmationMatches) {
          return 'Passwords do not match';
        }

        if (this.errors.has('password confirmation')) {
          return this.errors.first('password confirmation');
        }

        return null;
      },
      openLoginModal() {
        $('#logInLink').click();
      },
      emailConfirmationMatches() {
        return this.individualSignUpData.login_email === this.login_email_confirmation;
      },
      emailConfirmationHasErrors() {
        return this.errors.has('email confirmation') || !this.emailConfirmationMatches;
      },
      emailConfirmationError() {
        if (!this.emailConfirmationMatches) {
          return 'Emails do not match';
        }

        if (this.errors.has('email confirmation')) {
          return this.errors.first('email confirmation');
        }

        return null;
      },
    },

    watch: {
      isThisModalViewActive() {
        if (this.isThisModalViewActive) {
          this.openLoginModal();
        }
      }
    },

    methods: {
      async getTempDelegateAccountInfo() {
        const params = {
          session_id: this.sessionId
        };

        try {
          const { data: delegateInfo } = await axios.get('/api/proxify/account/temp-delegates', { params });

          this.individualSignUpData.given_name = delegateInfo.name.given_name;
          this.individualSignUpData.family_name = delegateInfo.name.family_name;
          this.delegateType = delegateInfo.delegate_id.type;
        } catch(error) {
          console.error(error);
        }
      },
      switchToSignIn() {
        this.$parent.$emit('change-modal', 'signin');
      },
      socialLoginUrl(delegate = 'google') {
        return `${ window.appVariables.sso_base_url }/oauth2/delegate-authorize?response_type=code&client_id=${ window.appVariables.api_client_id }&redirect_uri=${ window.appVariables.app_url }%2Flogin%2Fverify&delegate=${ delegate }`;
      },
      async signup() {
        if (this.processing) {
          return false;
        }

        this.formErrors = null;
        this.showDomainLoginAlert = false;

        try {
          if (!(await this.$validator.validateAll())) {
            return;
          }
        } catch (error) {
          console.error(error);
        }

        if (!this.hasCompany) {
          this.individualSignUpData.company.company = this.individualSignUpData.given_name + ' ' + this.individualSignUpData.family_name;

          if (this.individualSignUpData.company.company.length > 30) {
            this.individualSignUpData.company.company = this.individualSignUpData.given_name + ' ' + this.individualSignUpData.family_name.substring(0,1);
          }
        }

        this.processing = true;

        try {
          await axios.post('/api/proxify/account/register', this.individualSignUpData);

          if (this.signUpForNewsLetter) {
            // subscribe
            axios.post(`/newsletter-subscribe-email/${this.individualSignUpData.login_email}`, {});
          }

          this.processing = false;
          this.individualSignUpData.given_name = '';
          this.individualSignUpData.family_name = '';
          this.individualSignUpData.company.company = '';
          this.individualSignUpData.login_email = '';
          this.individualSignUpData.signup_country_code = '';
          this.formSuccess = `Your account has been created! You may now log in using the ${this.delegateType} button.`;
          this.formErrors = null;
          this.showForm = false;

          window.history.replaceState('', '', '/');

          let vm = this;

          this.$nextTick(() => {
            vm.errors.clear();

            setTimeout(function () {
              vm.$parent.$emit('change-modal', 'signin');
            }, 5000);
          });
        } catch (error) {
          console.error(error);

          const apiMessage = error.response.data.message;

          if (apiMessage.includes('Duplicate entry') || apiMessage.includes('already exists')) {
            this.showDomainLoginAlert = true;
          } else {
            this.formErrors = apiMessage;
          }

          this.processing = false;
        }
      },
    }
  }
</script>
