<template>
  <div class="form-group">
    <p class="frmValidation" :class="{ 'frmValidation--passed': newPassword.length > 7 }">
      <i class="frmIcon fa" :class="newPassword.length > 7 ? 'fa-check' : 'fa-times'"></i>
      Longer than 7 characters
    </p>
    <p class="frmValidation" :class="{ 'frmValidation--passed': has_uppercase }">
      <i class="frmIcon fa" :class="has_uppercase ? 'fa-check' : 'fa-times'"></i>
      Has a capital letter
    </p>
    <p class="frmValidation" :class="{ 'frmValidation--passed': has_lowercase }">
      <i class="frmIcon fa" :class="has_lowercase ? 'fa-check' : 'fa-times'"></i>
      Has a lowercase letter
    </p>
    <p class="frmValidation" :class="{ 'frmValidation--passed': has_number }">
      <i class="frmIcon fa" :class="has_number ? 'fa-check' : 'fa-times'"></i>
      Has a number
    </p>
    <p class="frmValidation" :class="{ 'frmValidation--passed': has_special }">
      <i class="frmIcon fa" :class="has_special ? 'fa-check' : 'fa-times'"></i>
      Has a special character
    </p>
  </div>
</template>

<script>
  export default {
    name: 'password-validation',

    props: {
      newPassword: String,
      confirmPassword: String,
    },

    data() {
      return {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
      };
    },

    watch: {
      isValidPassword() {
        if (this.isValidPassword) {
          this.$emit('error-msg', '');
        }
        this.$emit('validate-password', this.isValidPassword);
      },

      newPassword() {
        this.password_check();
      },

      confirmPassword() {
        this.passwordConfirmationMatches;
        this.$emit('password-errors', this.passwordConfirmationHasErrors);
        this.$emit('error-msg', this.passwordConfirmationError);
      },
    },

    computed: {
      isValidPassword() {
        if (
          this.newPassword !== '' &&
          this.confirmPassword !== '' &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special &&
          this.passwordConfirmationMatches
        ) {
          return true;
        }

        return false;
      },

      passwordConfirmationMatches() {
        return this.newPassword === this.confirmPassword;
      },

      passwordConfirmationHasErrors() {
        return (
          this.errors.has('password confirmation') ||
          !this.passwordConfirmationMatches
        );
      },

      passwordConfirmationError() {
        if (!this.passwordConfirmationMatches) {
          return 'Passwords do not match';
        }

        if (this.errors.has('password confirmation')) {
          return this.errors.first('password confirmation');
        }

        return null;
      },
    },

    methods: {
      password_check() {
        this.has_number = /\d/.test(this.newPassword);
        this.has_lowercase = /[a-z]/.test(this.newPassword);
        this.has_uppercase = /[A-Z]/.test(this.newPassword);
        this.has_special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
          this.newPassword
        );
      },
    },
  };
</script>

<style scoped>
  .frmLabel {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .frmValidation {
    font-size: 13px;
  }

  .frmValidation--passed {
    color: #717b85;
  }

  .frmIcon {
    color: #eb0029;
  }

  .frmValidation--passed .frmIcon {
    color: #0fa140;
  }
</style>