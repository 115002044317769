export const authModule = {
  state: {
    me: JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : {
          name: {
            display_name: "",
            formatted_name: "",
            given_name: "",
            middle_name: "",
          },
          profile_image_url: "",
          stats: {
            bookmarked_projects: 0,
            recent_orders: 0,
            wishlist_products: 0,
          },
          badges: [],
          twitter: null,
          facebook_url: null,
          id: "",
          company_name: "",
          gender: null,
          website: null,
        },
    isLoggedIn: false,
  },

  getters: {
    me: (state) => state.me,
    isLoggedIn: (state) => state.isLoggedIn,
  },

  mutations: {
    setMe(state, value) {
      state.me = value;
    },
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
  },
};
