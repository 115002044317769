import { mapMutations } from "vuex";
import shopPricing from "./shop_pricing";
import product from "./product";
import store from "../vuex/store";
import dataLayer from "./data-layer.js";
import modalNotifications from "./modal-notifications.js";

export default {
  store,

  mixins: [shopPricing, product, dataLayer, modalNotifications],

  data() {
    return {
      data: {
        quantity: {
          value: "1",
          weight: {
            value: "1",
            unit: "lb",
          },
        },
      },
      multipleItems: false,
      swatchItem: false,
      pricePerUnit: "$0.00",
      swatchCost: "$0.00",
      addToCartTotal: 0,
      okCancelCartItems: [],
    };
  },

  computed: {
    cart: {
      get() {
        return this.$store.getters.localCart;
      },
      set(value) {
        this.setLocalCart(value);
      },
    },

    itemJustAddedToCart: {
      get() {
        return this.$store.getters.localItemJustAddedToCart;
      },
      set(value) {
        this.setLocalItemJustAddedToCart(value);
      },
    },

    cartItems() {
      if (this.cart) {
        return this.cart.items;
      } else {
        return [];
      }
    },

    totalCartItems() {
      if (this.cartItems) {
        return this.cartItems.length;
      } else {
        return 0;
      }
    },
  },

  created() {
    this.createApiSession();
  },

  methods: {
    ...mapMutations(["setLocalCart", "setLocalItemJustAddedToCart"]),

    async createApiSession() {
      try {
        axios.post("/api/start-session");
      } catch (error) {
        console.error(error);
      }
    },

    showWarning(sku) {
      return (
        !sku.startsWith("APP") &&
        !sku.startsWith("SE") &&
        !sku.startsWith("K-") &&
        !sku.startsWith("AH-") &&
        !sku.startsWith("R-")
      );
    },

    okAction() {
      if (this.okCancelAction === 'addToCart') {
        this.addToCart(this.okCancelProduct, true, false);
      } else if (this.okCancelAction === 'updateCartItem') {
        this.updateCart(this.okCancelProduct, this.okCancelCartItems, true, false);
      } else if (this.okCancelAction === 'subscribe' && this.isLoggedIn ){
        this.subscribe(this.okCancelProduct, this.okCancelQuantity);
      } else if (this.okCancelAction === 'subscribe' && !this.isLoggedIn) {
        alert('To activate product email alerts, please log into your account with a valid email.');
        window.appVariables.bus.$emit(this.okCancelAction, true);
      } else if (this.okCancelAction === 'openLogin') {
        this.openLogin();
      } else {
        window.appVariables.bus.$emit(this.okCancelAction, true);
      }
    },

    cancelAction() {
      if (this.cancelFunction === 'openLogin') {
        this.openLogin();
      } else if (this.okCancelAction === 'addToCart') {
        this.addToCart(this.okCancelProduct, false, true);
      } else if (this.okCancelAction === 'updateCartItem') {
        this.updateCart(this.okCancelProduct, this.okCancelCartItems, false, true);
      }  else if (this.okCancelAction.includes('Gallery')) {
        window.appVariables.bus.$emit('cancelGalleryUpdate', null);
      }
    },

    isSwatchItem(product) {
      let name = product.name.toLowerCase();

      return name.includes("swatch") && !name.includes("set");
    },

    async addSwatchesToCart(project) {
      const url = `/api/proxify/domains/cerakote/shop/cart`;

      if (!this.isLoggedIn && this.totalCartItems >= 50) {
        this.hasTooManyItems();

        return;
      }

      for (const productColor of project.colors) {
        if (!productColor.sku.startsWith("RAL") && !productColor.discontinued) {
          let productUrl = `/api/proxify/domains/cerakote/shop/products/${productColor.product_id}`;

          try {
            let productResponse = await axios.get(productUrl);
            let cartResponse = await axios.post(url, data);
            const productObject = productResponse.data;
            const data = this.dataObject('product_id', productObject.swatch_product.id, 1);

            this.setSwatchPrice(cartResponse);
            this.windowEmit("cart-items-added", productObject, cartResponse.data, data, true, true);
            this.updateDataLayer(productObject.data, data.quantity.value, undefined);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },

    async addSwatchToCart(product) {
      const url = `/api/proxify/domains/cerakote/shop/cart`;

      if (product.discontinued) {
        return;
      }

      if (!this.isLoggedIn && this.totalCartItems >= 50) {
        this.hasTooManyItems();

        return;
      }

      if (product.swatch_product.id != null) {
        const swatchUrl = `/api/proxify/domains/cerakote/shop/products/${product.swatch_product.id}`;
        const data = this.dataObject(
          "product_id",
          product.swatch_product.id,
          "1"
        );

        try {
          let swatchResponse = await axios.get(swatchUrl);
          let cartResponse = await axios.post(url, data);
          const swatchObject = swatchResponse;

          this.setSwatchPrice(cartResponse);
          this.windowEmit("cart-items-added", swatchObject, cartResponse.data, data, false, true);
          this.updateDataLayer(swatchObject.data, data.quantity.value, undefined);
        } catch (error) {
          console.error(error);

          const apiMessage = error.response.data.message;

          if (apiMessage.includes('Cannot add more')) {
            this.$root.lockedTitle = 'Unable to Add Product';
            this.$root.lockedMessage = apiMessage;
            this.$root.okCancel = false;
            $('#informationModal').modal('show');
          }

          if (error.response.status === 400 && apiMessage.includes("Cannot add more than 2 swatches")) {
            let errTitle = "Unable to Add Swatch"
            let errMsg = `${product.quantity_available > 2 ? "Swatches have a quantity limit of 2 per swatch. " : product.name + " " + product.sku + "has a limit of 1. "}` + "If you would like to order more, please contact us at 1-866-774-7628.";
            this.errorModal(errTitle, errMsg);

            return;
          }
        }
      } else {
        const productId = product.product_id;
        let productUrl = `/api/proxify/domains/cerakote/shop/products/${productId}`;

        try {
          let swatchUrl = `/api/proxify/domains/cerakote/shop/products/${productObject.swatch_product.id}`;
          let productResponse = await axios.get(productUrl);
          let swatchResponse = await axios.get(swatchUrl);
          let cartResponse = await axios.post(url, data);
          const productObject = productResponse.data;
          const swatchObject = swatchResponse.data;
          const data = this.dataObject("product_id", productObject.swatch_product.id, "1");

          this.windowEmit("cart-items-added", swatchObject, cartResponse.data, data, false, true);
          this.updateDataLayer(swatchObject.data, data.quantity.value, undefined);
        } catch (error) {
          console.error(error);
        }
      }
    },

    findItemById(items, id) {
      for (let item of items) {
        if (item.product_id === id) {
          return item;
        }
      }

      return null;
    },

    windowEmit(
      type,
      product,
      cart,
      data,
      multipleItems = false,
      swatchItem = false
    ) {
      if (data != null) {
        window.appVariables.bus.$emit(type, {
          product: product,
          cart: cart,
          data: data,
          multipleItems: multipleItems,
          swatchItem: swatchItem,
          showBanner: true,
        });
      } else {
        window.appVariables.bus.$emit(type, {
          product: product,
          cart: cart,
        });
      }
    },

    dataObject(type, id, amountEntered = 1) {
      switch (type) {
        case "product_id":
          return {
            product_id: id,
            quantity: {
              value: amountEntered > 1 ? amountEntered : "1",
            },
          };

        case "variant_id":
          return {
            variant_id: id,
            quantity: {
              value: amountEntered > 1 ? amountEntered : "1",
            },
          };

        default:
          return {
            quantity: {
              value: amountEntered > 1 ? amountEntered : "1",
            },
          };
      }
    },

    async addColorsToCart(colors) {
      const url = `/api/proxify/domains/cerakote/shop/cart`;

      if (!this.isLoggedIn && colors.length + this.totalCartItems > 50) {
        this.hasTooManyItems();

        return;
      }

      for (const color of colors) {
        if (!color.discontinued) {
          try {
            const postPayload = this.dataObject("product_id", productObject.product_id);
            const response = await axios.post(url, postPayload);

            this.windowEmit("cart-items-added", productObject, response.data, postPayload);
            this.updateDataLayer(productObject.data, data.quantity.value, "Creation Detail");
          } catch (error) {
            console.error(error);
          }
        }
      }
    },

    async addKitItemsToCart(kit) {
      if (kit.kit_items.length) {
        const url = `/api/proxify/domains/cerakote/shop/cart/kit-items`;

        // Add the main kit product to the order first
        const params = [];
        params.push({ product_id: kit.id });

        // Go through each kit item to add products based on the
        // predefined products and ones chosen by the customer
        kit.kit_items.forEach(function (item) {
          if (item.shop_product) {
            // add products selected by the customer
            // based on the kit item 'Rule'
            if (item.shop_product.length > 0) {
              item.shop_product.forEach(function (itemProduct) {
                let payload = {
                  quantity: {
                    value: itemProduct.order_quantity
                      ? itemProduct.order_quantity
                      : 1,
                  },
                  kit_id: kit.id,
                  kit_rule_id: item.id,
                };

                if (itemProduct.product_id) {
                  payload["variant_id"] = itemProduct.id;
                } else if (
                  itemProduct.variants &&
                  itemProduct.variants.length > 0
                ) {
                  payload["variant_id"] = itemProduct.variants[0].id;
                } else if (itemProduct.variant_id) {
                  payload["variant_id"] = itemProduct.variant_id;
                } else {
                  payload["product_id"] = itemProduct.id;
                }

                params.push(payload);
              });
            } else {
              if (item.type === "picklist") {
                if (item.selected) {
                  // add products selected by the customer
                  // based on the kit item 'Picklist'
                  let payload = {
                    quantity: { value: item.amount },
                    kit_id: kit.id,
                    kit_rule_id: item.id,
                  };

                  if (item.shop_product.product_id) {
                    payload["variant_id"] = item.shop_product.id;
                  } else if (
                    item.shop_product.variants &&
                    item.shop_product.variants.length > 0
                  ) {
                    payload["variant_id"] = item.shop_product.variants[0].id;
                  } else if (item.shop_product.variant_id) {
                    payload["variant_id"] = item.shop_product.variant_id;
                  } else {
                    payload["product_id"] = item.shop_product.id;
                  }

                  params.push(payload);
                }
              } else {
                // add products predefined by the kit
                let payload = {
                  quantity: { value: item.amount },
                  kit_id: kit.id,
                  kit_rule_id: item.id,
                };

                if (item.shop_product.product_id) {
                  payload["variant_id"] = item.shop_product.id;
                } else if (
                  item.shop_product.variants &&
                  item.shop_product.variants.length > 0
                ) {
                  payload["variant_id"] = item.shop_product.variants[0].id;
                } else if (item.shop_product.variant_id) {
                  payload["variant_id"] = item.shop_product.variant_id;
                } else {
                  payload["product_id"] = item.shop_product.id;
                }

                params.push(payload);
              }
            }
          }
        });

        try {
          let response = await axios.post(url, params);

          this.setPricePerUnit(kit.pricing, 1);
          this.windowEmit("cart-items-added", kit, response.data, params, true, false);
          this.changeCartPrice(kit, 1);
          this.updateDataLayer(kit, 1, "Product Detail (Kit)");
        } catch (error) {
          console.error(error);

          if (error) {
            this.lockedTitle = "Limit 1 Kit";
            this.lockedMessage =
              "There is a limit of 1 kit item per checkout. Remove the current kit from your cart to choose a different kit, or continue with your current selection.";
            this.okCancel = false;
            $("#informationModal").modal("show");

            return;
          }
        }
      }
    },

    async subscribe(item, amount) {
      let payload = {
        email: this.mePrimaryEmail,
        sku: item.sku,
        quantity: amount,
      };
      axios
        .post("/api/proxify/domains/cerakote/shop/out-of-stock", payload)
        .then((response) => {
          if (response.status / 100 === 2) {
            let responsedata = {};
            if (response.data != undefined) {
              responsedata = response.data;
            } else {
              responsedata = response;
            }
            let title = "You have just subscribed!";
            let msg = "You have just subscribed to stock notifications for this product! We will alert you whenever this product is back in stock.";

            this.subscription(title, msg);
          }
        })
        .catch((response) => {
          if (error.response.data.message == "You are already subscribed to be notified when this product is back in stock.") {
            let title = "You are already subscribed!";
            let msg = "You have already subscribed to receive alerts for this product. We will inform you once this product is made available.";

            this.subscription(title, msg);
          }
        });
    },

    async openLogin() {
      $("#logInLink").trigger("click");
    },

    async subscribeNotify(product, okAction = false, cancelAction = false) {
      let productUrl = `/api/proxify/domains/cerakote/shop/products/${product.product_id}`;

      this.loading = true;

      try {
        let productResponse = await axios.get(productUrl);
        const productObject = productResponse.data;

        if (productObject.discontinued) {
          return;
        }

        let amountEntered = 1;

        const invalidQuantity =
          parseFloat(amountEntered) > productObject.quantity_available;

        if (
          this.validStockProduct(productObject.sku) &&
          invalidQuantity &&
          !okAction &&
          !cancelAction
        ) {
          let actionType = this.isLoggedIn ? "subscribe" : "subscribeManual";
          let cancelText = this.isLoggedIn ? ["Notify Me", "No"] : ["Submit", "Login"];
          let cancelFunc = this.isLoggedIn ? "" : "openLogin";

          this.qtyUnavailable(null, product, product, actionType, this.isLoggedIn, amountEntered, cancelText, cancelFunc);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async addToCart(product, okAction = false, cancelAction = false) {
      if (product.discontinued) {
        return;
      }

      if (!this.isLoggedIn && this.totalCartItems >= 50) {
        this.hasTooManyItems();

        return;
      }

      const url = `/api/proxify/domains/cerakote/shop/cart`;
      let productId = "";
      let amountEntered = $("#amountEntered").val();
      let stockLevel = product.stock_level;

      if (!amountEntered) {
        amountEntered = 1;
      }

      const invalidQuantity =
        parseFloat(amountEntered) > product.quantity_available;

        const validationCheck = this.validStockProduct(product.sku) && invalidQuantity && !okAction && !cancelAction;

      if (
        validationCheck&&
        this.isLoggedIn &&
        stockLevel === "long_term_out_of_stock"
      ) {
        this.qtyUnavailable(null, product, product, "subscribe", false, amountEntered, ["Notify Me", "No"], null);

        return;
      } else if (
        validationCheck&&
        !this.isLoggedIn &&
        stockLevel == "long_term_out_of_stock"
      ) {
        this.qtyUnavailable(null, product, product, "subscribeManual", true, amountEntered, ["No", "Log In"], "openLogin");

        return;
      } else if (
        validationCheck &&
        !okAction &&
        !cancelAction
      ) {
        this.qtyUnavailable(null, product, product, "addToCart", false, null, ["Yes", "No"], null);

        return;
      } else if (this.validStockProduct(product.sku) && invalidQuantity && cancelAction) {
        if (product.quantity_available === 0) return;

        $("#amountEntered").val(product.quantity_available);
        amountEntered = product.quantity_available;
      }

      if (this.cartItems) {
        for (const item of this.cartItems) {
          if (
            item.product.sku === product.sku &&
            this.validStockProduct(product.sku) &&
            item.quantity.value >= product.quantity_available &&
            !okAction &&
            !cancelAction
          ) {
            this.qtyUnavailable(null, item.product, product, "subscribe", false, null, ["Notify Me", "No"], null);

            return;
          } else if (
            item.product.sku === product.sku &&
            this.validStockProduct(product.sku) &&
            parseFloat(amountEntered) > product.quantity_available &&
            cancelAction
          ) {
            $("#amountEntered").val(product.quantity_available);
            amountEntered = product.quantity_available;
          }
        }
      }

      if (product.id != null) {
        let data = "";
        let productUrl = null;
        productId = product.id;

        if (product.product_id) {
          data = this.dataObject("variant_id", productId, amountEntered);
          productUrl = `/api/proxify/domains/cerakote/shop/products/${product.product_id}`;
        } else if (product.variants && product.variants.length) {
          data = this.dataObject(
            "variant_id",
            product.variants[0].id,
            amountEntered
          );
          productUrl = `/api/proxify/domains/cerakote/shop/products/${product.variants[0].product_id}`;
        } else {
          data = this.dataObject("product_id", productId, amountEntered);
        }

        try {
          let productObject = null;

          if (productUrl) {
            let productResponse = await axios.get(productUrl);
            productObject = productResponse.data;
            product["featured_image_url"] = productObject.featured_image_url;
          }

          let response = await axios.post(url, data);

          this.setPricePerUnit(product.pricing, data.quantity.value);
          this.windowEmit("cart-items-added", product, response.data, data, false, false);
          this.changeCartPrice(product, data.quantity.value);
          this.updateDataLayer(product, data.quantity.value, "Product Detail");
        } catch (error) {
          console.error(error);
        }
      } else {
        productId = product.product_id;

        let productUrl = `/api/proxify/domains/cerakote/shop/products/${productId}`;

        try {
          let productResponse = await axios.get(productUrl);
          const productObject = productResponse.data;
          let data = "";

          if (productObject.variants.length) {
            data = this.dataObject(
              "variant_id",
              productObject.variants[0].id,
              "1"
            );
          } else {
            data = this.dataObject("product_id", productObject.id, "1");
          }

          let cartResponse = await axios.post(url, data);

          this.setPricePerUnit(productObject.pricing, data.quantity.value);
          this.windowEmit("cart-items-added", productObject, cartResponse.data, data, false, false);
          this.updateDataLayer(productObject.data, data.quantity.value, "Product Detail");
        } catch (error) {
          console.error(error);
        }
      }
    },

    changeCartPrice(product, quantity) {
      if (!product.variants || !product.variants.length) {
        let correctedAmount = quantity;
        let total = null;

        if (product.pricing[0].price_per_base_quantity.unit === "item") {
          let pricePerItem = product.pricing[0].price_per_base_quantity.amount;
          total = (quantity * pricePerItem).toFixed(2);
        } else {
          let pricing = product.pricing;
          let price1 = pricing[0].price_per_base_quantity;
          let price2 = {};
          let priceQuantityMin2 = 0;
          let price3 = {};
          let priceQuantityMin3 = 0;
          if (pricing.length > 1) {
            price2 = pricing[1].price_per_base_quantity;
            priceQuantityMin2 = pricing[1].starting_quantity.value;
          }
          if (pricing.length > 2) {
            price3 = pricing[2].price_per_base_quantity;
            priceQuantityMin3 = pricing[2].starting_quantity.value;
          }

          let calculatedPrice = null;

          if (pricing.length > 2) {
            if (quantity < priceQuantityMin2) {
              total = quantity * price1.amount;
              calculatedPrice = price1.formatted;
            } else if (quantity < priceQuantityMin3) {
              total = quantity * price2.amount;
              calculatedPrice = price2.formatted;
            } else {
              total = quantity * price3.amount;
              calculatedPrice = price3.formatted;
            }
          } else if (pricing.length > 1) {
            if (quantity < priceQuantityMin3) {
              total = quantity * price2.amount;
              calculatedPrice = price2.formatted;
            } else {
              total = quantity * price2.amount;
              calculatedPrice = price2.formatted;
            }
          } else {
            total = quantity * price1.amount;
            calculatedPrice = price1.formatted;
          }
        }

        this.addToCartTotal = parseFloat(total).toFixed(2);
      } else {
        this.calculateCartVariantTotalPrice(product, quantity);
      }
    },

    calculateCartVariantTotalPrice(product, quantity) {
      let variantPrice = parseFloat(
        product.pricing[0].price_per_base_quantity.amount
      );

      this.addToCartTotal = (variantPrice * quantity).toFixed(2);
    },

    async updateCart(
      product,
      cartItems,
      okAction = false,
      cancelAction = false
    ) {
      var item = null;
      this.loading = true;

      cartItems.forEach(function (cartitem) {
        if (
          product.id != null &&
          (cartitem.product.product_id === product.id ||
            cartitem.product.product_id === product.product_id)
        ) {
          item = cartitem;
        }
      });

      const url = `/api/proxify/domains/cerakote/shop/cart/items/${item.id}`;

      if (product.id != null) {
        if (product.product) {
          if (
            this.validStockProduct(product.product.sku) &&
            parseFloat(product.quantity.value) > product.quantity_available &&
            cancelAction
          ) {
            product.quantity.value = product.quantity_available;
            item.quantity.value = product.quantity_available;
          }

          const data = {
            quantity: {},
          };

          data.quantity = {
            value: product.quantity.value,
          };

          try {
            let response = await axios.patch(url, data);
            let responsedata = {};

            if (response.data != undefined) {
              responsedata = response.data;
            } else {
              responsedata = response;
            }

            window.appVariables.bus.$emit("cart-updated", responsedata);
          } catch (error) {
            console.error(error);
          } finally {
            this.loading = false;
          }
        } else {
          let amountEntered = $("#quantityEntered").val();

          if (amountEntered > 0) {
            if (
              this.validStockProduct(product.sku) &&
              parseFloat(amountEntered) > product.quantity_available &&
              !okAction &&
              !cancelAction
            ) {
              this.qtyUnavailable(null, item.product, product, "updateCartItem", null, null, ["Yes", "No"], null);

              return;
            } else if (
              this.validStockProduct(product.sku) &&
              parseFloat(amountEntered) > product.quantity_available &&
              cancelAction
            ) {
              $("#quantityEntered").val(product.quantity_available);
              amountEntered = product.quantity_available;
            }

            const data = this.dataObject(
              null,
              null,
              $("#quantityEntered").val()
            );

            try {
              let response = await axios.patch(url, data);

              this.windowEmit("cart-items-added", product, response.data, data, false, false);
              this.updateDataLayer(product, data.quantity.value, "Cart");
            } catch (error) {
              console.error(error);
            } finally {
              this.loading = false;
            }
          } else {
            window.app.data.quantity.value = 1;
            this.loading = false;
          }
        }
      } else {
        let productId = product.product_id;
        let productUrl =`/api/proxify/domains/cerakote/shop/products/${productId}`;

        try {
          let productResponse = await axios.get(productUrl);

          if ($("#quantityEntered").val() > 0) {
            const data = this.dataObject(
              null,
              null,
              $("#quantityEntered").val()
            );
            let cartResponse = await axios.patch(url, data);

            this.windowEmit("cart-items-added", productResponse.data, cartResponse.data, data, false, false);
            this.updateDataLayer(productResponse.data, data.quantity.value, "Cart");
            this.loading = false;
          } else {
            window.app.data.quantity.value = 1;
            this.loading = false;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },

    changeHeaderPrice() {
      const pricing = this.itemJustAddedToCart.product.pricing;
      const inputAmt = this.data.quantity.value;
      let correctedAmount = inputAmt;

      if (inputAmt % 1 != 0) {
        correctedAmount = Math.trunc(inputAmt);
        this.data.quantity.value = correctedAmount;
      }

      this.setPricePerUnit(pricing, correctedAmount);
    },

    setSwatchPrice(response) {
      let orderSwatches = 0;

      for (const item of response.data.items) {
        if (item.price.amount === "0.00") {
          orderSwatches++;
        }
      }

      if (orderSwatches > 5) {
        let orderSwatchesCost = parseFloat(
          (orderSwatches - 5) * 0.2 + this.swatchPrice
        ).toFixed(2);
        this.swatchCost = "$" + orderSwatchesCost;
      } else {
        this.swatchCost = "$" + this.swatchPrice;
      }
    },

    setPricePerUnit(pricing, inputAmt) {
      if (pricing.length > 2) {
        if (inputAmt >= pricing[2].starting_quantity.value) {
          this.pricePerUnit = pricing[2].price_per_base_quantity.formatted;
        } else if (inputAmt >= pricing[1].starting_quantity.value) {
          this.pricePerUnit = pricing[1].price_per_base_quantity.formatted;
        } else {
          this.pricePerUnit = pricing[0].price_per_base_quantity.formatted;
        }
      } else if (pricing.length > 1) {
        if (inputAmt >= pricing[1].starting_quantity.value) {
          this.pricePerUnit = pricing[1].price_per_base_quantity.formatted;
        } else {
          this.pricePerUnit = pricing[0].price_per_base_quantity.formatted;
        }
      } else {
        this.pricePerUnit = pricing[0].price_per_base_quantity.formatted;
      }
    },

    validStockProduct(sku) {
      if (!sku) return false;

      if (
        !sku.startsWith("APP") &&
        !sku.startsWith("SE") &&
        !sku.startsWith("1H-") &&
        !sku.startsWith("R-") &&
        !sku.startsWith("AH-")
      ) {
        return true;
      }
    },

    updateDataLayer(item, qty, location) {
      const dataLayerGaParams = {
        type: this.itemJustAddedToCart.product.type
          ? this.itemJustAddedToCart.product.type
          : "item",
        item: item ? item : this.itemJustAddedToCart.product.data,
        cartTotal: this.cart.total.amount ? this.cart.total.amount : 0,
        total: this.addToCartTotal ? this.addToCartTotal : 0,
        qty: qty ? qty : 0,
        customerId: this.me.customer_id ? this.me.customer_id : undefined,
        locationId: location ? location : undefined,
      };

      // this.setGADataLayer(dataLayerGaParams);
      this.setGA4DataLayer(dataLayerGaParams);
    },
  },
};
