<template>
  <div class="modal fade" id="homepageVideoModal" tabindex="-1" role="dialog" aria-labelledby="projectUploadLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">See Why Brands Trust Cerakote</h4>
        </div>
        <div class="modal-body">
          <section>
            <iframe ref="videoIframe" width="100%" height="415" :src="videoUrl" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
            </iframe>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HomepageVideoModal',
  data() {
    return {
      videoUrl: 'https://www.youtube.com/embed/iV80WsxraCY?si=UCGKdG2hOQgyWstW',
    };
  },
  mounted() {
    // Listen to the modal hide event to reset the video
    $('#homepageVideoModal').on('hidden.bs.modal', this.modalReset);
  },
  beforeDestroy() {
    // Clean up the event listener
    $('#homepageVideoModal').off('hidden.bs.modal', this.modalReset);
  },
  methods: {
    closeModal() {
      $('#homepageVideoModal').modal('hide');
    },
    modalReset() {
      // Reset the video by clearing the iframe's src attribute
      this.videoUrl = '';
      // Optionally, set it back after a short delay for later replays
      this.$nextTick(() => {
        this.videoUrl = 'https://www.youtube.com/embed/iV80WsxraCY?si=UCGKdG2hOQgyWstW';
      });
    },
  },
};
</script>

<style scoped lang="scss">
#homepageVideoModal {

  label {
    font-weight: normal;
  }

  .modal-content {
    background-color: #fff;
    color: #333;
    border-radius: 6px;

    .modal-header {
      border-bottom: 1px solid #e5e5e5;
      padding: 0 15px 10px 15px;

      .close {
        color: #333;
        font-size: 25px;
        padding: 0;
      }
    }

    .modal-body {
      padding-bottom: 0;

      section {
        margin-bottom: 0.8rem;
      }
    }
  }
}
</style>
