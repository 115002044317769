import autoload from "./autoload";
import auth from "./mixins/auth";
import cart from "./mixins/cart";
import images from "./mixins/images";
import initDom from "./mixins/init_dom";
import project from "./mixins/project";
import NotificationPopup from "./components/modals/NotificationPopup";
import HomepageVideoModal from './components/modals/HomepageVideoModal.vue';

autoload();

window.app = new Vue({
  el: '#vue-home',

  mixins: [initDom, auth, cart, images, project],

  components: {
    NotificationPopup,
    HomepageVideoModal,
  },

  data() {
    return {
      popup: window._PR?.popup || null,
      newProducts: window._PR?.feed?.sections[0]?.items || [],
      trendingGalleryProjects: window._PR?.feed?.sections[1]?.items || [],
      trendingColors: window._PR?.feed?.sections[2]?.items || [],
      newsPost: window._PR?.feed?.sections[3]?.featured_item || [],
      newsPostPublishDate: [],
      myArticles: [],
      article: window._PR?.feed?.sections[4]?.featured_item || [],
      banners: [],
      lockedTitle: '',
      lockedMessage: ''
    }
  },

  computed: {
    showNoTrendingColors() {
      return !this.trendingColors.length
    },
    showNoTrendingProjects() {
      return !this.trendingGalleryProjects.length
    },
    showNoNewColors() {
      return !this.newProducts.length
    },
  },

  created() {

    const vm = this

    if (vm.newsPost) {
      vm.newsPost.body = vm.newsPost.body.substring(0, 500) + '...'
      const publishDate = moment(vm.newsPost.published_at, 'YYYY-MM-DD')
      vm.newsPost.published_at = publishDate.format('MMM D, Y')
      const createDate = moment(vm.newsPost.created_at, 'YYYY-MM-DD')
      vm.newsPost.created_at = createDate.format('MMM D, Y')
    }

    if (vm.article) {
      const createDate = moment(vm.article.created_at, 'YYYY-MM-DD')
      vm.article.created_at = createDate.format('MMM D, Y')
    }

    if (!vm.showNoNewColors) {
      for (let product of vm.newProducts) {
        if (Object.keys(product).length > 0 && product.image_url != null && product.image_url.includes('?')) {
          product.image_url = product.image_url + '&'
        } else if (product.image_url != null) {
          product.image_url = product.image_url + '?'
        }
      }
    }

    if (!vm.showNoTrendingProjects) {
      for (let project of vm.trendingGalleryProjects) {
        if (Object.keys(project).length > 0 && project.featured_image_url != null && project.featured_image_url.includes('?')) {
          project.featured_image_url = project.featured_image_url + '&'
        } else if (project.featured_image_url != null) {
          project.featured_image_url = project.featured_image_url + '?'
        }
      }
    }

    if (!vm.showNoTrendingColors) {
      for (let product of vm.trendingColors) {
        if (Object.keys(product).length > 0 && product.image_url != null && product.image_url.includes('?')) {
          product.image_url = product.image_url + '&'
        } else if (product.image_url != null) {
          product.image_url = product.image_url + '?'
        }
      }
    }

  },
  mounted() {

    const vm = this

    $(window).on('resize load', function () {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 500) {
          $('.back-to-top').css('display', 'inline')
        } else {
          $('.back-to-top').css('display', 'none')
        }
      })
    })

    if (vm.popup !== null) {
      $('#notificationPopup').modal('show')
    }

    $('#homeCarousel')?.on('slid.bs.carousel', function () {
      const currentSlideIndex = $('div.active').index()
      const slideId = `slide-${currentSlideIndex}`
      const slideContents = document.getElementById(slideId).children[0].id

      vm.sendDataLayerSlideEvent(slideId, slideContents)
    })
  },

  methods: {

    saveArticle(article) {
      let vm = this
      let userDocUrl = `/api/proxify/me/articles/${article.id}`
      axios
        .put(userDocUrl)
        .then(({ data: response }) => {
          vm.myArticles.push(article.id)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    unsaveArticle(article) {
      let vm = this
      let userDocUrl = `/api/proxify/me/articles/${article.id}`
      axios
        .delete(userDocUrl)
        .then(({ data: response }) => {
          vm.myArticles = vm.myArticles.filter((e) => e !== article.id)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    openLoginModal() {
      $('#logInLink')?.click()
    },

    openVideoModal() {
      $('#homepageVideoModal')?.modal('show')
    },

    // Assign a label to the slide for tracking purposes
    bannerSlideLabel(label) {
      if (label !== null && label !== undefined && label !== '') {
        let newLabel = label.replace(/ /g, '-').toLowerCase()

        return newLabel
      }

      return (newLabel = 'video')
    },

    sendDataLayerSlideEvent(slideId, slideContents) {
      window.dataLayer.push({
        'event': 'homepage-hero',
        'category': slideId,
        'label': slideContents
      })
    }

  }

})

// displays the add-to-cart dropdown
require("./app_events");
