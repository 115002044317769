export default {

  data() {
    return {

      quantityNotice: false

    }
  },

  methods: {


    /**
     * @method ProductMinMaxQuantity
     * @author Sebastian Inman <sinman@nicindustries.com>
     * @param {*} product
     * Generates the min/max values for a product. Loops through all the categories
     * that the product belongs to and grabs the highest minimum and lowest maximum
     * values that it can find. Then, we compare the products base quantity to the
     * calculated minimum value - if the base quantity is higher, we use that instead.
     * Finally, we make sure the minumum value is at least one.
     */

    ProductMinMaxQuantity(product) {

      let temp = {};

      let min = Number.NEGATIVE_INFINITY;
      let max = Number.POSITIVE_INFINITY;

      if('categories' in product && Object.keys(product.categories).length > 0) {

        Object.keys(product.categories).map(i => {

          temp.min = product.categories[i].min_order_quantity;
          temp.max = product.categories[i].max_order_quantity;

          if(temp.min > min) min = temp.min;
          if(temp.max > max) max = temp.max;

        });

      }else{

        min = 1;
        max = 0;

      }

      if('base_quantity' in product) {

        min = min > product.base_quantity.value ? min : product.base_quantity.value;
        min = min >= 1 ? min : 1

      }else{

        min = 1;

      }

      return { min, max };

    },


    /**
     * @method ValidateProductQuantity
     * @author Sebastian Inman <sinman@nicindustries.com>
     * @param { Object } $event
     * @param { Object } minmax
     * This method validates the provided value against a min/max pair.
     * If the provided value is not within the range of the min/max pair,
     * we return an error.
     */

    async ValidateProductQuantity(value, minmax) {

      return new Promise((resolve, reject) => {

        let invalid = false;

        // Disallow the quantity value from being less
        // than the minimum allowed quantity or less than one.
        if(parseInt(value) <= 0 || parseInt(value) < parseInt(minmax.min)) {

          invalid = `This product must be ordered in a quantity of at least ${minmax.min}`;

        }

        // Disallow the quantity value from being greater than the
        // maximum allowed quantity, but only if the max is greater than zero.
        if(parseInt(minmax.max) > 0 && parseInt(value) > parseInt(minmax.max)) {

          invalid = `This product can not be ordered in a quantity greater than ${minmax.max}`;

        }

        if(!invalid) resolve();
        if(!!invalid) reject(invalid);

      });

    }


  }

}
