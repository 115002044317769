// This mixin contains project related common features like toggle bookmarks
// [8/4/22] this is all legacy code. I've made a few small modifications to get the bookmarking working, however, project detail pages do not acknowledge the users bookmark status. Also, reformatted the code per Beautify Code standards.

export default {
  methods: {
    toggleProjectBookmark($event, projectId) {
      const url = `/api/proxify/me/projects/bookmarked/${projectId}`;

      let classlist = $event.target.classList;
      let classlist2 = $event.target.parentElement.classList;

      if (classlist.contains("projectDetailsFlag")) {
        if (!classlist.contains("project-highlight")) {
          classlist.add("project-highlight");

          axios
            .put(url, {})
            .then(response => {
              dataLayer.push({
                event: "bookMarked",
                projectId: "${projectId}"
              });
              if (!response.status) {
                classlist.remove("project-highlight");
                throw Error("Could not add bookmark");
              }
            })
            .catch(response => alert("Could not add bookmark"));
        } else {
          classlist.remove("project-highlight");

          axios
            .delete(url, { data: {} })
            .then(response => {
              if (!response.status) {
                classlist.add("project-highlight");
                throw Error("Could not delete bookmark");
              }
            })
            .catch(response => alert("Could not delete bookmark"));
        }
      } else {
        if (
          classlist.contains("bookmark-project") &&
          !classlist.contains("project-highlight")
        ) {
          if (classlist.contains("projectFlag")) {
            classlist.add("project-highlight");
            $event.target.nextElementSibling.nextElementSibling.nextElementSibling.lastElementChild.firstElementChild.firstElementChild.classList.add(
              "project-highlight"
            );
          } else {
            if (
              $event.target.parentElement.parentElement.parentElement
                .parentElement.previousElementSibling === null
            ) {
              $event.target.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.add(
                "project-highlight"
              );
            } else {
              $event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.add(
                "project-highlight"
              );
            }
            classlist2.add("project-highlight");
          }
          axios
            .put(url, {})
            .then(response => {
              dataLayer.push({
                event: "bookMarked",
                projectId: "${projectId}"
              });
              if (!response.status) {
                if (classlist.contains("projectFlag")) {
                  classlist.remove("project-highlight");
                  $event.target.nextElementSibling.nextElementSibling.nextElementSibling.lastElementChild.firstElementChild.firstElementChild.classList.remove(
                    "project-highlight"
                  );
                } else {
                  if (
                    $event.target.parentElement.parentElement.parentElement
                      .parentElement === null
                  ) {
                    $event.target.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.remove(
                      "project-highlight"
                    );
                  } else {
                    $event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.remove(
                      "project-highlight"
                    );
                  }
                  classlist2.remove("project-highlight");
                }
                throw Error("Could not add bookmark");
              }
            })
            .catch(response => alert("Could not add bookmark"));
        } else {
          if (classlist.contains("projectFlag")) {
            classlist.remove("project-highlight");
            $event.target.nextElementSibling.nextElementSibling.nextElementSibling.lastElementChild.firstElementChild.firstElementChild.classList.remove(
              "project-highlight"
            );
          } else {
            if (
              $event.target.parentElement.parentElement.parentElement
                .parentElement.previousElementSibling === null
            ) {
              $event.target.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.remove(
                "project-highlight"
              );
            } else {
              $event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.remove(
                "project-highlight"
              );
            }
            classlist2.remove("project-highlight");
          }
          axios
            .delete(url, { data: {} })
            .then(response => {
              if (!response.status) {
                if (classlist.contains("projectFlag")) {
                  classlist.add("project-highlight");
                  $event.target.nextElementSibling.nextElementSibling.nextElementSibling.lastElementChild.firstElementChild.firstElementChild.classList.add(
                    "project-highlight"
                  );
                } else {
                  if (
                    $event.target.parentElement.parentElement.parentElement
                      .parentElement.previousElementSibling === null
                  ) {
                    $event.target.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.add(
                      "project-highlight"
                    );
                  } else {
                    $event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.classList.add(
                      "project-highlight"
                    );
                  }
                  classlist2.add("project-highlight");
                }
                //$event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling
                throw Error("Could not delete bookmark");
              }
            })
            .catch(response => alert("Could not delete bookmark"));
        }
      }
    },

    openLoginModal() {
      $("#logInLink").click();
    },

    likeProject(projectId) {
      let url = "/api/proxify/me/projects/liked/" + projectId;
      axios
        .put(url, {})
        .then(response => {
          if (!response.status) {
            throw Error("Could not like project");
          } else {
            $(".likeProject span")[0].innerText = response.data.like_count;
          }
        })
        .catch(response => alert("Could not like project"));
    },

    shareProject(social, projectId, projectName) {
      if (social === "facebook") {
        FB.ui(
          {
            method: "share",
            display: "popup",
            href: `${
              window.appVariables.app_url
            }/project-gallery/${projectId}/${this.slug(projectName)}`
          },
          function(response) {}
        );
      }
    },

    toggleBookmarked(e, projectId) {
      if (!this.isLoggedIn) {
        this.openLoginModal();
      } else {
        this.toggleProjectBookmark(e, projectId);

        if (this.bookmarked) {
          $("#" + projectId).remove();
        }
      }
    }
  }
};
